/*------------------------------------*\
    Component: Language Switcher
\*------------------------------------*/

.desktop-language-switcher {
  display: flex;
  .language-switcher-locale-session {
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 14px;
    padding: 2px;
    background-color: #fff;

    > li {
      order: 1;

      &:has(a.session-active) {
        order: 0;
        display: block;
        > a {
          margin-right: 4px;

          &::before {
            opacity: 1;
          }
          &:after {
            display: block;
          }
        }
      }

      &.zh-hans {
        > a {
          &::before {
            content: url('../../images/icons/icon-china.png');
          }
          &::after {
            content: 'CN';
          }
        }
      }

      &.en {
        > a {
          &::before {
            content: url('../../images/icons/icon-nz.png');
          }
          &::after {
            content: 'EN';
          }
        }
      }

      > a {
        color: inherit;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 0;

        &::before {
          line-height: 1;
        }

        &:after {
          display: none;
          font-size: 14px;
          margin-left: 3px;
          margin-right: 4px;
          font-weight: 400;
        }
      }
    }
  }
}

.mobile-language-switcher {
  display: flex;
  .language-switcher-locale-session {
    display: flex;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 14px;
    padding: 4px;

    > li {
      order: 1;

      &:has(a.session-active) {
        order: 0;
        display: block;
        > a {
          margin-right: 4px;

          &::before {
            opacity: 1;
          }
          &:after {
            display: block;
          }
        }
      }

      &.zh-hans {
        > a {
          &::before {
            content: url('../../images/icons/icon-china.png');
          }
          &::after {
            content: 'CN';
          }
        }
      }

      &.en {
        > a {
          &::before {
            content: url('../../images/icons/icon-nz.png');
          }
          &::after {
            content: 'EN';
          }
        }
      }

      > a {
        color: inherit;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 0;

        &::before {
          line-height: 1;
          //opacity: 0.6;
        }

        &:after {
          display: none;
          font-size: 14px;
          margin-left: 3px;
          margin-right: 4px;
          font-weight: 400;
        }
      }
    }
  }
}

// Old template
.language-switcher {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  color: #fff;
  width: 80px;

  .language-switcher-locale-session {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    z-index: 50;

    > li {
      color: #fff;
      order: 1;
      display: none;

      &:has(a.session-active) {
        order: 0;
        display: block;
      }

      &.zh-hans {
        > a {
          &::before {
            content: url('../../images/icons/icon-china.png');
          }
          &::after {
            content: 'CN';
          }
        }
      }

      &.en {
        > a {
          &::before {
            content: url('../../images/icons/icon-nz.png');
          }
          &::after {
            content: 'EN';
          }
        }
      }

      > a {
        color: inherit;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 0;

        &::before {
          line-height: 1;
        }

        &:after {
          font-size: 12px;
          margin-left: 8px;
          font-weight: bold;
        }
      }
    }
  }

  &:focus,
  &:active,
  &:hover {
    .language-switcher-locale-session {
      background-color: #fff;
      border: 1px solid #efefef;
      border-radius: 10px;
      width: 80px;

      > li {
        display: block;
        margin-bottom: 2px;
        color: #0a1a2a;
        > a {
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 4px;

          &:after {
            display: block;
          }
        }
      }
    }
  }
}
