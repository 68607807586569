body {
  @include less-than($screen-md) {
    padding-top: 80px;
  }
}

.tw-w-1-5 {
  @apply tw-w-1/4;
}

.tw-container {
  width: 100%;
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  @include larger-than($screen-xxl) {
    max-width: 1440px !important;
  }

  @include less-than($screen-sm) {
    padding: 1rem 2rem;
  }
}

.sidebar-first {
  .content {
    .sidebar {
      display: block;
    }

    .main-content {
      padding-left: 3rem;
    }
  }

  @include less-than($screen-sm){
    .content {
      .main-content{
        padding-left: 0;
        width: 100% !important;
      }
    }
  }

}

main {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.content {

  &#content-block{
    min-height: 40rem;
    margin: 0rem 0 10rem;
  }

  @include less-than($screen-sm) {
    margin: 0;
  }

  .breadcrumb {
    margin: .5rem 0 1.5rem;
  }

  .sidebar {
    display: none;
    .view-footer-category-links {display: none;}
  }
}

.region-sidebar-first{
  border-left:1px solid #D7D7D7;

  ul{

    li{
      margin:0;
      padding:0;
      display: block;
      float:none;
    }

    &.facetapi-facetapi-checkbox-links{
      li{
        a{
          padding: 0 !important;
          &:hover{
            padding: 0 !important;
          }
        }
        &:hover{
          border: none !important;
        }
      }
    }

    &:not(.facetapi-facetapi-checkbox-links){
      li{
        a{
          padding:1rem 0 1rem 2rem;
          background: none;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          &:hover{
            padding:1rem 0 1rem 1.7rem;
          }
        }

        &.active{
          background:#eee;
          border-left:3px solid #4D87CA;
        }
        &:hover{
          border-left:3px solid #4D87CA;
        }

      }
    }

  }
}

// Responsive
.desktop-only{
  @include less-than(979px) {
    display: none !important;
  }
}

.mobile-only{
  @include larger-than(980px) {
    display: none !important;
  }
}
