/*------------------------------------*\
  Our range
\*------------------------------------*/
.our-range{

    /** view **/
    .view-content{
        @apply 
        tw-w-full
        tw-grid; 
        gap: 2rem;
        h2{
            font-size: 3.5rem;
            a{
                color: #000;
            }
        }
        a{
            color: $color-primary;
        }
        .group-left{
            @apply tw-w-full tw-mb-4;
        }
        > .views-row{
            @apply 
            tw-flex
            tw-w-full;
            .vocabulary-category{
                @apply tw-flex-col;
            }
        }
    }

    @include larger-than($screen-md){
        .view-content{
            @apply tw-grid-cols-2; 
            gap: 4rem; 
        }
    }
    @include larger-than($screen-lg){
        .view-content{
            > .views-row{
                .vocabulary-category{
                    @apply tw-flex-row;
                }
                .group-left{
                    width: 250px;
                }
                .group-right{
                    flex: 1;
                }
                .group-right{
                    @apply tw-pl-16;
                }
            }
        }
    }

}   