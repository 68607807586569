/*------------------------------------*\
    Paeg: Checkout
\*------------------------------------*/

.page-cart-checkout {
  .region-content {
    table.cart-review {
      width: 100%;

      td.products,
      th.products {
        width: 60%;
      }
      thead {
        background-color: #4d87ca;
        color: #fff;
        th {
          height: 4rem;
          text-align: center;
        }
      }
      tbody {
        tr:not(:last-child) {
          border-bottom: 1px solid #eee;

          td {
            text-align: center;
            height: 60px;
          }
        }
        tr:last-child {
          height: 80px;
        }
      }
    }

    .uc-cart-checkout-form {
      > div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .fieldset,
        .form-actions {
          display: flex;
        }

        .address-pane-table {
          > table {
            width: 100%;

            .field-label {
              font-weight: normal;
              width: 30%;
              padding: 0;
              text-align: left;
            }

            td {
              padding-top: 1rem;
              padding-bottom: 1rem;
            }

            input,
            select,
            .chosen-single {
              margin: 0;
              background-color: #fff !important;
            }
          }
        }

        .panel-default {
          width: 100%;
          @include larger-than($screen-lg) {
            &#delivery-pane,
            &#billing-pane {
              width: calc(50% - 1rem);
            }
          }
        }

        //#delivery-pane {
        //  width: calc(50% - 1rem);
        //}

        .form-item{
          margin-bottom: 1rem;
        }

        #billing-pane {
          //width: calc(50% - 1rem);
          margin-left: auto;
          font-size: 1.5rem;
          font-weight: normal;
          color: #555;

          .control-label {
            display: flex;
            font-weight: 500;
            color: #555;
            align-items: center;

            input[type="checkbox"] {
              flex: 0 0 10px;
              margin-right: 1rem;
            }
          }

          .form-item-panes-billing-copy-address{
            .control-label{
              input[type="checkbox"]{
                position: relative;
                margin-top: 0;
              }
            }
          }

          .form-item-panes-billing-select-address{
            .control-label{
              color: #000;
            }
          }

        }

        #payment-pane {
          #line-items-div {
            @include less-than($screen-md) {
              width: 100%;
            }
          }

          .form-item-panes-payment-payment-method {
            padding: 0;
            margin-bottom: 0;
            .control-label,
            .form-radios {
              width: 100%;
            }

            > .control-label{
              position: relative;
              width: auto;
              flex: 0 0 auto;
              font-size: 12px;
              font-weight: normal;
              input{
                width: auto;
              }
            }

            #pxpay-includes{
              display: flex;
              flex-wrap: wrap;
              font-size: 0;
              align-items: center;

              input{
                visibility: hidden;
              }

              > a{
                display: flex;
                flex: 0 0 200px;
              }

              img{
                width: auto;
                height: auto;
              }

              br{
                display: none;
              }
            }

          }
        }

        #quotes-pane {
          #quote {
            border: none;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .control-label {
              display: flex;
              align-items: center;
            }
            input[type="radio"] {
              flex: 0 0 10px;
              margin-right: 1rem;
            }
          }
        }

        .form-item-panes-payment-payment-method {
          display: flex;
          align-items: center;

          input[type="radio"] {
            flex: 0 0 10px;
            margin-right: 1rem;
          }
          label {
            align-items: center;
            //display: flex !important;
          }
          @include less-than($screen-md) {
            width: 100%;
          }
        }

        #payment-details {
          width: 50%;
          @include less-than($screen-md) {
            width: 100%;
          }
        }

        @include less-than($screen-md) {
          #delivery-pane,
          #billing-pane {
            width: 100%;
            margin-left: 0;
          }
        }

        #edit-actions {
          justify-content: flex-end;
          button {
            margin-left: 1rem;
          }
        }
      }
    }

    .order-review-table {
      @include larger-than($screen-sm) {
        width: 80%;
      }
    }

    #uc-cart-checkout-review-form {
      .form-actions {
        position: relative;
        padding: 0 1rem 1rem 1rem;
        display: block;
        .btn {
          @include btn($color-primary);
        }
      }
    }

    fieldset.panel {
      border: 1px solid #ddd;
      margin-bottom: 5rem;

      .panel-heading {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        width: 100%;
        background: $color-primary;
        color: #fff;
        padding: 1rem 0.5rem 1rem 2rem;
      }

      .panel-body {
        padding: 2rem;

        input,
        select,
        textarea,
        select,
        .chosen-single {
          border: 2px solid lighten($color-primary, 30%) !important;
          width: 100%;
          padding: 1rem;
          color: #333;
        }

        label.control-label {
          font-weight: bold;
          //margin-bottom: 1rem;
          display: block;
        }

        .help-block {
          color: #999;
          margin: 0 0 1em 0;
          font-size: 1.3rem;
        }

        .form-type-select {
          width: 100%;
        }
        .chosen-container {
          width: 100% !important;
          font-size: 100%;
        }
        .chosen-single {
          height: auto;
          padding: 1rem;
          border-radius: 0;
          border: 1px solid #eee;
          box-shadow: none;
          background: transparent;

          > div {
            display: flex;
            height: 100%;
            align-items: center;

            &::before {
              content: "\f107";
              font-family: "FontAwesome";
            }
            b {
              display: none;
            }
          }
        }

        button[type="submit"] {
          @include btn($color-primary);
        }
      }
    }

    #delivery-address-pane {
      table {
        margin-top: 2rem;

        tr {
          height: 6rem;

          td.field-label {
            color: #555;
            font-weight: 500;
            font-size: 1.5rem;
            width: 15rem;
            text-align: left;
          }
        }

        input {
          border: none;
          background-color: #f1f1f1;
          color: #555;
          border-radius: 3px;
        }
      }
    }

    #edit-cancel {
      @include btn($color-danger);
    }

    #edit-continue {
      @include btn($color-success);
    }

    /*
    ======================================================
     Block: Checkout Order
    ======================================================
    */

    table.cart-review tbody tr {
      td {
        height: auto;
        &.products {
          text-align: left;
        }
        &.price {
          text-align: center;
        }
      }
    }

    .order-review-table {
      width: 100%;
      border: none;
      margin-top: 30px;

      .pane-title-row {
        background-color: lighten($color-primary, 20%);
        border: none;
        color: #fff;
      }

      tbody {
        tr {
          td {
            padding-top: 15px;
            padding-bottom: 15px;
            height: auto;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.page-cart-checkout
  .region-content
  #uc-cart-checkout-review-form
  .form-actions {
  padding: 0 1rem;
  .btn {
    font-weight: bold;
  }
}
