/*------------------------------------*\
    Component: Webforms
\*------------------------------------*/

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.form-required {
  color: #e53e3e;
}

.panel-body {
  padding: 25px;
  margin-bottom: 0.5em;
  float: left;
  width: 100%;
}

.panel-default > .panel-heading {
  color: #ffffff;
  background-color: #0a1a2a;
  border: none !important;
}

.panel-heading {
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  width: 100%;

  font-size: 21px;
  line-height: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

form .form-control {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

form button, form .btn-default, form .btn {
  font-size: 1.875rem;
  background-color: #4d87ca;
  color: #FFF;
  font-weight: 600;
  border-color: #295588;
  outline: none;
  padding: 10px 15px;
}

.help-block {
  color: #b31616;
  font-size: 12px;
}

.glyphicon-question-sign:before {
  content: "\e085";
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}

/*------------------------------------*\
  Pls. do not hardcode webform ID's because 
  it will not work on the live site. live site has diff ID's
  add .webform-cols to your form wrapper instead
\*------------------------------------*/
.webform-cols .file_uploads .form-file,
.file_uploads .form-file,
.webform-cols .webform-component-file .form-file,
#webform-client-form-16223 .file_uploads .form-file,
#webform-client-form-16224 .file_uploads .form-file ,
#webform-client-form-16225 .file_uploads .form-file,
#webform-client-form-16226 .webform-component-file .form-file,
#webform-client-form-30 .webform-component-file .form-file{
  margin-right: 20px !important;
  width: 73% !important;
}

.webform-submit {
  font-size: 1.5rem;
  background-color: #629020 !important;
  color: #FFF;
  font-weight: 600;
  border-color: #629020 !important;
  outline: none;
}

.form-actions{
  text-align: center;
  margin:0 auto;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.checkbox input[type=checkbox],
.radio input[type=radio]{
  position: absolute;
  margin-left: -20px;
  margin-top: 4px;
  line-height: normal;
}

.checkbox, .radio {
  position: relative;
  display: block;
  margin-bottom: 10px;
}



.checkbox label, .radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.product-replacement-request-form .webform-client-form .form-item, 
.soil-guard-service-request-form .webform-client-form .form-item, 
.leather-guard-service-request-form .webform-client-form .form-item {
  margin-top: 10px;
  float: left;
  padding: 0 30px 0 0;
}

.product-replacement-request-form,
.soil-guard-service-request-form,
.leather-guard-service-request-form {

  .webform-client-form{
    display: block;
    .form-item{
      margin-top:10px;
      
      float: left;    
      padding: 0 30px 0 0;
      @include less-than(767px) {
        width: 100%;
        float: none;    
        padding: 0 0 0 0;
      }
    }
    .form-date{
      .form-item{
        padding-right:0;
      }
    }
    #edit-submitted-service-request-details-reason-for-service-request{
      .form-item{
        width: 100%;
        margin-bottom:0;
      }
    }
    
  }

}

/*------------------------------------*\
  Pls. do not hardcode webform ID's because 
  it will not work on the live site. live site has diff ID's
  add .webform-cols to your form wrapper instead
\*------------------------------------*/
.webform-cols,
#webform-client-form-30,
#webform-client-form-16226,
#webform-client-form-16225,
#webform-client-form-16224,
#webform-client-form-16223
{

  .tabledrag-toggle-weight-wrapper{
    display: none;
  }

    .form-group{
      margin-bottom: 15px;
      width: 46%;
      float: left;
      margin-right: 30px;

      @media (max-width: 768px)  {
        width: 100%;
      }

    }

    .leather-guard-service-request-form .webform-client-form .form-item{
      width: 100% !important;
      padding:0;
      margin:0;
    }

    .file_uploads,
    .webform-component-file {
      
      margin-bottom: 15px;
      width: 46%;
      float: left;
      margin-right: 30px;
      .panel-body{
        padding:0;
      }
      label{
        font-size: 14px;
      }
      .form-file{
        margin-right: 20px;
      }
      .form-item{
        width: 100%;;
      }
      .panel-heading{
        color: #333333;
        background: none !important;
        padding-left:0;
        padding-bottom:0;
        font-weight: 700;
      }

      table{
        font-size:14px;
        width: 100%;
        background: #fafafa;
        th{
          text-align: left;
          background: #eee;
        }
        .btn{
          font-size: 1.4rem;
          padding: 4px 5px;
          border:none;
          background-color: #333;
        }
      }

      .alert.alert-danger {
        font-size: 12px;
    } 

      @media (max-width: 768px)  {
        width: 100%;
      }

    }

    .form-item-submitted-title-product-purchase-date-day,
    .form-item-submitted-title-product-purchase-date-month,
    .form-item-submitted-title-product-purchase-date-year,

    .form-item-submitted-product-details-product-purchase-date-day,
    .form-item-submitted-product-details-product-purchase-date-month,
    .form-item-submitted-product-details-product-purchase-date-year,

    .form-item-submitted-product-details-product-purchase-date-day,
    .form-item-submitted-product-details-product-purchase-date-month,
    .form-item-submitted-product-details-product-purchase-date-year,

    .form-item-submitted-product-purchase-date-day,
    .form-item-submitted-product-purchase-date-month,
    .form-item-submitted-product-purchase-date-year,

    .form-item-submitted-what-date-did-the-incident-occur-day,
    .form-item-submitted-what-date-did-the-incident-occur-month,
    .form-item-submitted-what-date-did-the-incident-occur-year,

    .form-date .form-group

    {
      width:100px !important;
      float:left;
      margin-right: 15px;

      @media (max-width: 480px) {
        width:100% !important;
     }

     
    }

    

    .webform-component-markup{
      width:100% !important;
      margin-bottom: 2rem;
     }

     @media (max-width: 480px) {
      
      .panel-body {
        padding-left: 0;
        padding-right: 0;
      }
    }

}
.node-webform footer{
  display: none;
}