/*------------------------------------*\
    Page: contact
\*------------------------------------*/
.page-node.contact {
  .content-wrapper {
    display: flex;
  }

  .region-content {
    width: 40%;
    padding-left: 5rem;

    .contact-detail {
      margin-bottom: 3rem;
      font-size: 1.6rem;

      .contact-label {
        display: inline-block;
        width: 9rem;
      }

      .contact-text {
        display: inline-block;
        width: 32rem;
        vertical-align: top;
      }
    }

    .contact-map {
      iframe {
        width: 100%;
        height: 400px;
      }
    }
  }

  .region-content-left {
    width: 60%;

    p {
      margin-bottom: 2rem;
    }

    .form-item {
      margin-bottom: 1.5rem;
    }

    form {
      input,
      textarea {
        border: 1px solid #eee;
        border-radius: 3px;
        padding: 1rem;
        width: 100%;
      }

      .form-actions {
        button {
          @include btn($color-primary);
        }
      }
    }
  }

  @include less-than($screen-md) {
    .content-wrapper {
      flex-wrap: wrap;
    }

    .region-content {
      width: 100%;
      padding-right: 0;
      margin-bottom: 4rem;
    }

    .region-content-left {
      width: 100%;
    }
  }
}
