/*------------------------------------*\
    Page: Product List
\*------------------------------------*/
.page-products {
  .content#content-block {
    .view {
      &[class*='view-product-and-product-collection'] {
        .view-content {
          padding: 2rem;
          background-color: #fafafa;
          border: 1px solid #f1f1f1;
          .node-teaser {
            background-color: #fff;
            border: 1px solid #e2e2e2;
          }
        }
      }
    }
  }

  //#efefef
  // border #e2e2e2

  .page_header {
    display: block;

    .contextual-links-wrapper {
      top: -15px;
    }
  }

  .sidebar {
    .region-sidebar-first {
      border: none;
    }

    .search-filter {
      font-size: 1.3rem;
      font-weight: 300;
      background: #fafafa;
      margin-bottom: 2rem;
      padding: 2rem;
      border: 1px solid #f1f1f1;

      .block-title {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #f1f1f1;
      }

      li {
        margin-bottom: 0.5rem;
        padding-bottom: 0.5em;

        ul {
          width: 100%;
          margin-left: 13px;
          margin-top: 10px;
        }

        a {
          width: 200px;
          position: absolute;
        }
      }
    }
  }

  .region-content {
    .view-content {
      @include grid-layout(4, 3rem);
      @include product;

      @include less-than($screen-xl) {
        @include grid-layout(3, 3rem);
      }

      @include less-than($screen-lg) {
        @include grid-layout(2, 3rem);
      }

      @include less-than($screen-md) {
        @include grid-layout(1, 0);
      }
      // Teaser only
      .product-info {
        .uc-price {
          font-weight: 500;
          font-size: 1.8rem;
          color: #203c5d;
        }
        .price-suffixes {
          font-size: 12px;
        }
        .crossout-price {
          display: block;
        }
      }
    }

    // overwrite hidden qty
    .add-to-cart {
      border-top: 1px solid #f1f1f1;
      margin-top: 1rem;
      padding-top: 1rem;

      > form {
        > div {
          display: flex;
        }
      }

      .form-item-qty {
        display: flex !important;
        justify-content: center;
        align-items: center;

        .form-control {
          border-radius: 4px;
          border: 1px solid #828282;
          text-align: center;
          max-width: 5rem;
          margin-right: 0.5rem;
          padding-left: 2px;
          padding-right: 2px;
        }

        label {
          font-size: 0;
          padding-right: 1rem;
          &::before {
            content: 'Qty';
            font-size: 1.6rem;
          }
          span {
            display: none;
          }
        }
      }

      .form-actions {
        margin-top: 0 !important;
        display: flex;
        flex: 1;
        > button {
          //width: 100px !important;
          padding-left: 1rem !important;
          padding-right: 1rem !important;
          font-size: 12px !important;
        }
      }
    }

    // Pager
    .pager.pager--infinite-scroll {
      margin-top: 3rem;
      padding: 0;
      list-style: none;
      .pager__item {
        list-style: none;
        text-align: center;
        > a {
          @include myanimated;
          font-weight: 500;
          font-size: 1.8rem;
          color: #fff;
          padding: 1rem 2rem;
          background-color: $color-orange;
          border: 1px solid $color-orange;
          border-radius: 5px;
          &:hover {
            background-color: #86bc86;
            border-color: #86bc86;
          }
        }
      }
    }
  }

  @include less-than($screen-lg) {
    .sidebar {
      position: fixed;
      width: 80vw !important;
      background: #fff;
      height: calc(100vh - 7rem);
      padding: 2rem;
      left: 0;
      top: auto;
      transition: all 0.5s;
      transform: translateX(-80vw);
      top: 7rem;
      z-index: 99;

      .sidebar-trigger::after {
        content: 'filter';
        position: absolute;
        right: -2rem;
        height: 8rem;
        width: 2rem;
        background: #4d87ca;
        color: #fff;
        writing-mode: vertical-lr;
        text-align: center;
        line-height: 2rem;
        top: 0;
        text-transform: uppercase;
        font-size: 1.4rem;
        transform: translateY(10px);
        top: 50%;

        &:hover {
          background: lighten($color-primary, 5%);
        }
      }

      &.opened {
        box-shadow: 1px 1px 10px #aaa0a0;
        transform: translateX(0);

        .sidebar-trigger::after {
          content: 'close';
        }
      }
    }

    .main-content {
      //width: 100% !important;
      padding-left: 0 !important;
    }
  }
}

/*
======================================================
  Product Taxonomy Term Page
======================================================
*/
.products {
  &.page-taxonomy-term {
    .block-system {
      display: grid;
      grid-gap: 3rem;

      grid-template-columns: repeat(5, minmax(0, 1fr));
      .term-listing-heading {
        grid-column: 1 / 6;
      }

      @include less-than($screen-lg) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        .term-listing-heading {
          grid-column: 1 / 4;
        }
      }

      @include less-than($screen-sm) {
        grid-template-columns: auto !important;
        .term-listing-heading {
          grid-column: auto !important;
        }
      }

      .term-listing-heading {
        .field-name-category-to-products {
          a {
            font-weight: bold;
            &:hover {
              color: #0a1a2a;
              text-decoration: underline;
            }
          }
        }
      }

      .node-product {
        &.node-teaser {
          padding: 1.4rem;
          border: 0.8px solid #f1f1f1;
          height: 100%;
          @apply tw-flex tw-flex-col tw-justify-between;
          @include myanimated;

          //: ---------------------------------------------------------------

          &:hover {
            box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
            h2 a {
              color: $color-orange;
            }
          }

          &:after {
            display: none;
          }

          img {
            width: 100%;
            height: 200px;
            object-fit: contain;
          }

          h2 {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.6;
            margin: 1rem 0;

            a {
              @include myanimated;
              &:hover {
                color: $color-orange;
              }
            }
          }

          .crossout-price {
            font-size: 1.8rem;
            text-decoration: line-through;
            color: #d43333;
          }

          .group-price-section {
            @apply tw-flex tw-flex-col;
          }

          .display-price {
            font-weight: 300;
            font-size: 1.5rem;
            color: #898989;
            text-align: left;

            .uc-price {
              font-weight: bold;
              font-size: 1.8rem;
              color: #203c5d;
            }

            .price-suffixes {
              font-size: 12px;
            }
          }

          .form-actions {
            position: relative;
            margin-top: 1rem;

            button {
              width: 100%;
              @include btn($color-primary);
            }

            .alert {
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0;
              background: $color-orange;
              color: $color-white;
              padding-right: 2.5rem;
              margin-bottom: 4rem;
              display: flex;
              flex-wrap: wrap;
              font-size: 1.2rem;

              strong {
                display: none;
              }

              > .close {
                color: $color-white;
              }
            }
          }

          .add-to-cart {
            form {
              p {
                display: none;
              }
              .form-item-qty {
                position: relative;
                display: flex;
                justify-content: space-between;

                .form-control {
                  text-align: center;
                  border-radius: 4px;
                  border: 1px solid #ddd;
                  text-align: center;
                }
              }
            }
          }

          //: ---------------------------------------------------------------
        }
      }
    }
  }
}

/*
======================================================
  Products
======================================================
*/

.products {
  &__favourites {
    margin-bottom: 2rem;
    padding: 2rem;
    margin-bottom: 2rem;
    border: 1px solid #e2e2e2;
    background: #efefef;

    .block-title {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 1.8rem;
    }

    .node {
      &.node-teaser {
        background-color: #fff;
      }
    }
  }
}
