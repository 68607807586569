/*------------------------------------*\
    Alert
\*------------------------------------*/
.region-alert{
    @apply tw-relative;
    @apply tw-w-full;
    > .block{
        @apply tw-flex;
        @apply tw-justify-center;
        @apply tw-items-center;
        @apply tw-text-white;
        @apply tw-bg-red-600;
        @apply tw-p-4;
        p{
            @apply tw-text-center;
            @apply tw-mb-0;
            font-size: 1.4rem;
            &::before{
                content: "\f071";
                font-family:'FontAwesome';
                @apply tw-mr-2;
            }
        }
    }
}