/*------------------------------------*\
    Global Page Styles
\*------------------------------------*/

/*
======================================================
: Global hidden elements
======================================================
*/
#sidebar-trigger {
  display: none;
}
.page_header {
  display: none;
}


/*
======================================================
: Userbar, hide and show different element acrroding
  to user login status
======================================================
*/
a.user-logout {
  display: flex !important;
}
a.user-login {
  display: none !important;
}

.logged-in {
  a.user-logout {
    display: none !important;
  }
  a.user-login {
    display: flex !important;
  }
}

/*
======================================================
: Inifinite scroll loader
======================================================
*/
.views_infinite_scroll-ajax-loader {
  margin: 4rem 0;
  display: flex;
  justify-content: center;
}