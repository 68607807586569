/*------------------------------------*\
   Header
\*------------------------------------*/

header {
  font-size: 1.4rem;
  font-weight: 300;

  @include less-than(980px) {
    display: none;
  }

  .top_banner {
    background-color: #0a1a2a;
    height: 4rem;
    @apply tw-flex tw-items-center;

    .region-top-banner{
      @apply tw-flex;
      @apply tw-justify-end;
    }

    .user-bar {
      @apply tw-flex tw-justify-end;
      ul.user-login {

        a {
          display: flex;
          align-items: center;
        }

        img {
          display: inline;
          width: 1.6rem;
          height: 1.6rem;
          object-fit: contain;
          margin-right: 0.5rem;
        }
      }

      a {
        padding: 0 2rem;
        color: #fff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .topmenu {
    position: relative;
    .header-ribbons {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      @include less-than($screen-lg) {
        display: none;
      }

      .ribbon {
        width: 44px;
        height: 100%;
        margin: 0;
        padding: 0;
        float: left;
        position: relative;
        transition: all 0.3s;

        &.ribbon-1 {
          background-color: #5075a0;
          &:before {
            content: "";
            display: block;
            width: 44px;
            height: 40px;
            position: absolute;
            background: #5075a0;
            top: -40px;
            z-index: 99;
          }
        }

        &.ribbon-2 {
          background-color: #a2bcda;
          &:before {
            content: "";
            display: block;
            width: 44px;
            height: 57px;
            position: absolute;
            background: #a2bcda;
            bottom: -57px;
            z-index: 99;
          }
        }

        &.ribbon-3 {
          background-color: #dedede;
        }

        &.ribbon-4 {
          background-color: #bbbbbb;
        }
      }
    }
  }

  .region-topmenu {
    padding: 3rem 0;

    @apply tw-flex tw-w-full tw-items-center;

    /** Logo **/
    .block-header-logo {
      flex-grow: 1;
      align-items: center;
      display: flex;
      padding-right: 5rem;
      transition: all 0.5s;
      img {
        height: 5rem;
        max-width: none;
        @include less-than($screen-lg) {
          height: 4rem;
        }
      }
      @include less-than(1645px ){
        padding-left: 17rem;
      }
      @include less-than(1024px ){
        padding-left: 0;
      }
    }

    /** Search Form **/
    .block-header-search {
      width: 65rem;
      @include searchbar;
    }

    /** Shopping Camm-blockerrt **/
    .block-uc-cart {
      height: 100%;
      position: relative;
      display: flex;
      width: 42px;
      margin-left: 2rem;

      .cart-block-title-bar,
      .cart-block-items,
      .cart-block-summary-links,
      .cart-block-summary-total {
        display: none;
      }

      .block-title a span {
        background: none;
        background-image: url('/sites/all/themes/kodaweb-new/images/icons/cart.png');
        background-position: center;
        background-size: contain;
        width: 42px;
        height: 37px;
        background-repeat: no-repeat;
        display: inline-block;
      }

      .cart-block-summary-items {
        color: transparent;
      }

      .num-items {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #e98e05;
        text-align: center;
        line-height: 20px;
        border-radius: 100%;
        position: absolute;
        right: -5px;
        top: 8px;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}