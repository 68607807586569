/*------------------------------------*\
    Component: Top Image Region
\*------------------------------------*/

.topimg{
  overflow: hidden;

  .view-home-slide-show {
    position: relative;
  
    .slick {
      &:focus {
        outline: none;
      }
  
      img {
        width: 100%;
        height: 45rem;
        object-fit: cover;
      }
    }
  
    .home-slide-show-title {
      position: absolute;
      width: 100%;
      bottom: 5rem;
      color: #fff;
  
      .field-name-title {
        font-size: 5rem;
        text-transform: uppercase;
      }
    }
  
    .slick{
  
      .slide__content{
        .home-slide-show-title{
          z-index: 999;
        }
        &::after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); 
          background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); 
          background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); 
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
        }
      }
  
      &__slider{
        z-index: 50;
        position: relative;
      }
  
      &__arrow{
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 50;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include myanimated;
  
        .slick-arrow{
          @include myanimated;
          z-index: 99;
          width: 6rem;
          display: flex;
          outline: none;
          justify-content: center;
          align-items: center;
  
          &:hover{
            &::before{
              color: #FFF;
            }
          }
  
          &::before{
            font-family:'FontAwesome';
            font-size: 6rem;
            line-height: 1;
            color: #a2bcda;
            font-weight: normal;
            @include myanimated;
          }
  
          &.slick-prev{
            &::before{
              content: "\f104";
            }
          }
          &.slick-next{
            &::before{
              content: "\f105";
            }
          }
        }
  
  
        @include larger-than($screen-md){
          &:hover{
            .slick-arrow{
              opacity: 1;
            }
          }
          .slick-arrow{
            opacity: 0.2;
          }
        }
  
      }
    }
  }
  
}

.topimg-container {
  position: relative;

  img {
    width: 100%;
    height: 32rem;
    object-fit: cover;
  }

  h1 {
    position: absolute;
    bottom: 3rem;
    color: #fff;
    font-weight: 500;
    font-size: 6rem;
  }

  @include less-than($screen-md) {
    img {
      height: 20rem;
    }

    h1 {
      font-size: 4rem;
    }
  }
}
