/*------------------------------------*\
    Mixin: Tabs
\*------------------------------------*/
@mixin tabs {
  padding: 0;
  background-color: #fff;
  border: none;

  li {
    margin-right: 2rem;
    padding: 1rem 0;
    border: none;
    background: none;
    transition: all 0.3s;

    &:hover {
      background-color: #fff;
      border-bottom: 6px solid darken($color-primary, 10%);
    }

    &.active,
    &.selected {
      border-bottom: 6px solid $color-primary;
      padding: 1rem 0;

      a {
        color: #000;
      }
    }

    a {
      font-weight: 500;
      font-size: 1.8rem;
      color: #9c9c9c;
      transition: all 0.3s;
      padding: 0 !important;

      &:hover {
        background-color: #fff;
      }

      @include less-than($screen-sm) {
        font-size: 1.5rem;
      }
    }
  }

  @include less-than($screen-md) {
    display: flex;
    justify-content: space-between;

    li {
      margin-right: 0;
    }
  }
}
