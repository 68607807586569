/*------------------------------------*\
    Page: News List
\*------------------------------------*/
.page-news {
  .view-news {
    .view-content {
      @include grid-layout(4, 3rem);
      @include news;

      @include less-than($screen-lg) {
        @include grid-layout(2, 3rem);
      }

      @include less-than($screen-md) {
        @include grid-layout(1, 0);
        .views-row {
          margin-bottom: 3rem;
        }
      }
    }
  }
}
