/*------------------------------------*\
  Product Care
\*------------------------------------*/
.product-care{
    &--block{
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
        padding: 0;

        > div{
            padding: 0 !important;
        }

        a{
            padding: 0 !important;
            width: 100%;
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span{
                flex: 1;
                padding: 2rem 2rem;
                font-size: 2.5rem;
                text-align: center;
            }
            &:hover{
                color: $color-primary;
            }
        }
        img{
            width: 388px;
            height: 203px;
        }
        span{
            text-align: center;
        }
        /** Responsive **/
        @include larger-than($screen-lg){
            flex-direction: row;
            gap: 5rem;
            margin-top: 2rem;
        }
        @include larger-than($screen-md){
              
        }
    }
}