/*------------------------------------*\
    Component: Mega Menu
\*------------------------------------*/
.mega_menu {
  background-color: #203c5d;

  .tb-megamenu {
    background-color: #203c5d;
  }

  .tb-megamenu-block {
    margin: 0;
  }

  .dropdown-menu,
  .caret {
    border: none;
  }

  .tb-megamenu-nav {
    display: flex;
    justify-content: flex-end;

    > li {
      position: static !important;

      > a {
        transition: all 0.2s;
        background-color: #1a3452 !important;
        font-size: 1.8rem;
        font-weight: 500;

        &:hover, &:active {
          color: #fff;
        }
      }

      &:hover {
        > a {
          background-color: #fff !important;
          color: #1a3452 !important;
        }
      }

      &:first-child {
        a {
          color: transparent;
          background-image: url(/sites/all/themes/kodaweb-new/images/icons/home.svg);
          background-repeat: no-repeat;
          background-position: center;
        }

        &:hover {
          > a {
            color: transparent !important;
            background-image: url(/sites/all/themes/kodaweb-new/images/icons/home-hover.svg);
          }
        }
      }
    }

    // > li:nth-child(4) .tb-megamenu-submenu{
    //   display: block;
    // }

    .mega-dropdown-menu {
      width: 100% !important;

      a{
        @include myanimated;
      }

      /** Custom class name for navigation-queues assigned **/
      .navigation-queues{
        h2{
          width: 100%;
          text-align: center;
          padding: 0;
          margin: 1rem 0 0 0;
          font-size: 2.5rem;
          @include myanimated;
        }
        img{
          height: 203px;
          width: 100%;
          border: 4px solid #FFF;
          object-fit: cover;
          @include myanimated;
        }

        .views-row{
          &:hover{
            h2{
              a{
                color: $color-primary;
              }
            }
            img{
              border-color: $color-primary;
            }
          }
        }

      }

      .view-content {
        display: flex;
        padding: 1.5rem;
      }

      /** Sub menu Item**/
      .submenu--item {
        @apply tw-w-1/5;
        padding: 1rem;

        .field-content {
          @include squareImgBox;
        }

        img {
          transition: all 0.3s;
        }

        .views-field-name {
          font-size: 2rem;
          text-align: center;
          margin-top: 1rem;
        }

        &:hover {
          img {
            transform: scale(1.03);
          }

          .views-field-name a {
            color: lighten(#000, 10%);
          }
        }
      }

      h3 {
        display: none;
      }

      .group-left {
        width: 40%;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          max-width: none;
          border: 4px solid #FFF;
          @include myanimated;
        }

        a{
          &:hover{
            img{
              border: 4px solid $color-primary;
            }
          }
        }
      }

      .group-right {
        width: 60%;
        padding: 0 2rem;

        .field-name-title,
        .field-name-category-custom-header-link {

          h2 {
            font-size: 2.5rem;
            a{
              padding-left: 0;
              padding-right: 0;
              @include myanimated;

              &:hover{
                color: $color-primary;
              }

            }
          }
        }

        .taxonomy-term-description {
          margin: 1rem 0;

          p {
            font-weight: 300;
          }
        }

        .field-name-category-to-products {
          a {
            padding: 0;
            color: #2b8ec6;
            &:before {
              content: ">";
              font-size: 1.6rem;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}
