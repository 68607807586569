.koda-p__card {
  position: relative;
  width: 100%;
  height: 100%;
  @apply tw-flex tw-items-center tw-justify-center;


  &.hero {
    .koda-p__card--image {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .koda-p__card--body {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      @apply tw-flex tw-items-center tw-justify-center;
    }
  }
}
