/*------------------------------------*\
    Page: Category
\*------------------------------------*/
.page-taxonomy {
  .sidebar {
    // sidebar menu
    .view-footer-category-links {
      display: block;
      border-left: 1px solid #D7D7D7;

      .views-row {
        font-weight: 300;
        font-size: 1.5rem;
        padding: 1.5rem 2rem;
        border-left: 4px solid #fff;
        transition: all 0.5s;

        &.active, &:hover {
          background-color: #f8f8f8;
          border-left: 4px solid #4D87CA;
        }
      }
    }
  }

  .region-content {

    .block-system > p {display: none;}

    h2 {
      font-size: 3rem;
      font-weight: 300;
      border-bottom: 1px solid #D9D9D9;
      padding: 0rem 0 2rem;
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.6;
    }

    img {
      margin: 2rem 0;
    }

    .field-name-category-to-products {
      width: 100%;
      text-align: center;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      padding: 2rem 0;
      margin-top: 5rem;

      a {
        @include link;
      }
    }
  }
}
