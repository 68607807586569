/*------------------------------------*\
    Theme: admin
\*------------------------------------*/
.path-user {
  #contentblock {
    margin: 200px auto;
    width: 80%;
  }
}

.not-logged-in {
  .tabs--primary {
    display: none;
  }
}

/*
======================================================
: Editing Tabs
======================================================
*/
.tabs--primary {
  @apply tw-flex;
  padding: 0.5rem 1rem;
  border: 1px dashed #eee;
  margin: 1rem 0;

  a {
    color: #333;
    margin-right: 1rem;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

/*
======================================================
: User Login Page
======================================================
*/
.page-user {
  .region-content {
    form {
      margin: 0 auto;

      .help-block {
        font-size: 12px;
        display: none;
      }
      .form-item {
        margin-bottom: 10px;

        label {
          width: 100px;
          display: inline-block;
        }
        input {
          border: 1px solid #eee;
          height: 50px;
          width: 100%;
          text-indent: 20px;
          color: #555;
        }
      }

      button[type="submit"] {
        background: #333;
        color: #fff;
        border-radius: 3px;
        padding: 5px 20px;
        float: right;
      }
    }
  }

  .region-content {
    table {
      @apply tw-table-auto;
      background-color: #fff;
      position: relative;
      border: 1px solid #eee;
      position: relative;
      width: 100%;
      margin-top: 2rem;
      display: inline-table;

      thead {
        tr {
          border-bottom: 1px solid #eee;
        }
      }

      tr {
        th,
        td {
          font-size: 1.4rem;
          padding: 1rem 1rem;
          border-bottom: none;
          @include larger-than($screen-lg) {
            &.views-field-order-id {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.table-responsive {
  position: relative;
  @include less-than($screen-lg) {
    width: 100%;
    overflow-x: scroll;
    border: 1px solid #eee;
  }
}

/** Page user elements **/
.nav-tabs {
  @include less-than($screen-lg) {
    flex-direction: column;
  }
}

/*
======================================================
: masquerade
======================================================
*/
section#block-masquerade-masquerade {
  position: fixed;
  width: 100vw;
  bottom: 0;
  height: 4rem;
  background: #333;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  z-index: 50;

  h2 {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .form-item {
    position: relative;
  }

  .description {
    position: relative;
    display: flex;

    .placeholder {
      color: $color-primary;
    }

    ul,
    ol,
    dl,
    ul li {
      margin-bottom: 0;
    }

    #quick_switch_links {
      margin-left: 10px;
      display: flex;
      a {
        margin-left: 0.5rem;
      }
    }
  }

  form {

    // Search Dropdown
    .dropdown {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 99;
      left: 0;
      bottom: 100% !important;

      .dropdown-menu {
        width: 100%;
        height: auto;
        max-height: 200px;
        overflow-y: scroll;
        padding: 8px;
        background-color: #fff !important;
        border-radius: 0;
        bottom: 0;
        z-index: 99;
        float: left;
        position: absolute;
        > li {
          position: relative;
          width: 100%;
          color: #000;
        }
      }

      a {
        position: relative;
        float: left;
        width: 100%;
        display: block;
        padding: 0 8px;
        color: #000;
        font-size: 12px !important;
      }
    } // dropdown

    div{
      position: relative;
      align-items: center;
      &:not(.inline-flex){
        display: flex;
      }
    }

    div.container-inilne {
      position: relative;
      justify-content: center;
      align-items: center;
    }

    input {
      border-radius: 3px;
      background-color: #000;
      color: #c1c1c1;
      padding: 0.5rem;
      text-indent: 0.5rem;
    }

    button[type="submit"] {
      
      display: inline-block;
      margin: 0 2rem;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      @include btn(#797979);
    }
  }
}
