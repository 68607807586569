/*------------------------------------*\
    Component: Mobile Header
\*------------------------------------*/
.mobile_header {
  display: none;
  width: 100vw;
  height: 7rem;
  padding: 1rem;
  position: fixed;
  top: 0;
  z-index: 99;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 4px #eee;

  @include less-than(980px) {
    display: flex;
  }

  img {
    margin: 1rem;
    height: 3rem;
  }

  .hamburger-menu {
    &:focus {
      outline: none;
    }
  }

  .region {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    .mobile-header-logo {
      margin-right: auto;
    }
  }

  /*------------------------------------*\
    Block Cart
  \*------------------------------------*/
  .block-uc-cart {
    height: 100%;
    position: relative;
    display: flex;
    width: 42px;
    margin-left: 0.5rem;

    .cart-block-title-bar,
    .cart-block-items,
    .cart-block-summary-links,
    .cart-block-summary-total {
      display: none;
    }

    .block-title a span {
      background: none;
      background-image: url(/sites/all/themes/kodaweb-new/images/icons/cart.png);
      background-position: center;
      background-size: contain;
      width: 42px;
      height: 37px;
      background-repeat: no-repeat;
      display: inline-block;

      @include less-than($screen-lg) {
        width: 30px;
        height: 30px;
      }
    }

    .cart-block-summary-items {
      color: transparent;
    }

    .num-items {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #e98e05;
      text-align: center;
      line-height: 20px;
      border-radius: 100%;
      position: absolute;
      right: -5px;
      top: 8px;
      font-size: 12px;
      color: #fff;
    }
  }
}
