/*------------------------------------*\
    Component: Mobile Menu
\*------------------------------------*/
#mmenu_left {
  .block-header-logo {
    display: none;
  }

  .mm-list {
    li {
      border: none;
    }
    span,
    a {
      padding: 0;
    }
  }

  ul.mm-panel {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .user-bar {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-around;

      &.user-login {
        justify-content: center;
        li {
          margin-right: 1rem;
        }
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 1rem;
      }
      a {
        display: flex;
      }
    }
  }

  .block-header-search {
    @include searchbar;
    input {
      width: 100% !important;
    }
  }

  .block-uc-cart {
    margin: 2rem 0;
    .cart-block-title-bar,
    p.cart-block-items {
      display: none;
    }

    .cart-block-summary {
      width: 100%;

      .cart-block-summary-links {
        ul.links {
          display: flex;
          justify-content: space-between;
          color: #e98e05;
          border-top: 1px solid #c4ccda;
          border-bottom: 1px solid #c4ccda;
          margin-top: 1rem;
          padding: 1rem 0;
        }
      }

      .cart-block-summary-items {
        &::before {
          content: '\f291';
          font-family: $font-awesome;
          margin-right: 1rem;
        }
      }

      .cart-block-summary-total {
        text-align: right;
        color: #fff;
        label {
          margin-bottom: 0;
        }
      }
    }
  }

  .top-banner-menu {
    li {
      border-bottom: 1px solid #25282f;
      border-top: 1px solid #444141;
      a {
        padding: 1rem 0;
      }
    }
  }

  /** Menu List **/
  .mm-list {
    &.mmenu-mm-list-level-1 {
      li {
        margin-bottom: 0.5rem;
      }
      > li {
        a {
          i {
            display: none;
          }
        }
      }
    }

    &.mmenu-mm-list-level-2,
    &.mmenu-mm-list-level-3 {
      padding-top: 2rem;

      .mm-subtitle {
        margin-bottom: 1rem;
      }

      .mm-subclose {
        position: relative;
        color: #fff;
        font-size: 18px;
        padding: 4px 0;
        margin: 0;
        background: none;
        &::before {
          content: '\f104';
          position: absolute;
          font-family: 'FontAwesome';
          left: 0;
          top: 0;
          height: 100%;
          text-indent: 0;
          transform: rotate(0);
          border: none !important;
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
