/*------------------------------------*\
    Component: Button
\*------------------------------------*/

@mixin btn ($color) {
  &{
    background-color: $color;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    font-size: 1.4rem;
    text-align: center
  }
  &:hover,
  &:active {
    background-color: lighten($color, 10%);
  }

}

.btn-primary{
  @include btn($color-primary);
}

.btn-secondary{
  @include btn($color-orange);
}
