/*------------------------------------*\
    Page: Product
\*------------------------------------*/
.node-type-product {
  .content {
    .sidebar {
      display: none;
    }

    .main-content {
      width: 100% !important;
      padding-left: 0;
    }
  }

  .product-wrapper {
    justify-content: space-between;
  }

  .product-left {
    width: 40%;
    position: relative;

    .product-image {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      border: 0.8px solid #e0e0e0;
      padding: 2rem;
    }

    .slick{
      position: relative;
      width: 100%;
      height: 100%;
      &__arrow{
        position: absolute;
        width: 100%;
        padding: 0 2rem;
        top: 0;
        margin-top: 40%;
        display: flex;
        justify-content: space-between;
        button.slick-arrow{
          position: relative;
          outline: none;
          font-size: 0;
          padding: 0.5rem 1rem;
          background-color: rgba(255, 255, 255, 0.8);
          opacity: 0.5;
          &:hover{
            opacity: 1;
          }
          &.slick-prev,
          &.slick-next{
            &::before{
              font-family: $font-awesome;
              font-size: 2.5rem;
              line-height: 1;
              font-weight: normal;
            }
          }
          &.slick-prev{
            &::before{
              content: "\f104";
            }
          }
          &.slick-next{
            &::before{
              content: "\f105";
            }
          }
        }
      }
    }

  }

  .product-right {
    width: 52%;

    // proudct title
    .field-name-title {
      h1 {
        font-size: 3rem;
        font-weight: normal;
        line-height: 2;
      }
    }

    // product price info
    .group-price-info-wrapper {
      display: flex;
      flex-direction: column;
      padding: 1.5rem 0;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      margin: 1.5rem 0;

      .product-info {
        font-size: 1.6rem;
        font-weight: 300;
        color: #8f8f8f;
      }

      .display-price {
        text-align: left;
        padding: 0;
        margin: 0;

        .uc-price {
          font-size: 3.8rem;
          color: #d43333;
          font-weight: 500;
          line-height: 56px;
        }

        .crossout-price{
          font-size: 1.8rem;
          display: inline;
          text-decoration: line-through;
          color: #d43333;
        }

      }
    }

    // product description
    .group-description-section {
      h3 {
        margin-bottom: 2rem;
      }

      .field-name-body {
        font-size: 1.6rem;
        font-weight: 300;
        color: #5e5e5e;
        line-height: 1.7;
        letter-spacing: 0.02em;
      }
    }

    // add to cart form
    .add-to-cart {
      margin-top: 3rem;

      form > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include less-than($screen-sm){
          justify-content: center;
        }

        p,
        fieldset.panel {
          display: none;
        }

        .form-item {
          height: 100%;

          input {
            border: 1px solid #828282;
            border-radius: 3px;
            text-align: center;
            color: #333;
            height: 37px;
            max-width: 5rem;
          }
        }

        .form-item-qty {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          label {
            width: auto;
            padding-right: 1rem;
            display: flex;
            font-size: 0;
            &::before{
              content: "Qty";
              font-size: 1.6rem;
            }
            span{
              display: none;
            }
          }
        }

        .form-actions {
          position: relative;
          margin-left: 1rem;
          
          button {
            width: 18rem;
            border-color: $color-orange;
            @include btn ($color-orange);

            @include less-than($screen-sm){
              width: auto;
            }
          }

          //.uc-ajax-cart-alt-status-messages
          .alert {
            position: absolute;
            min-width: 37rem;
            top: 100%;
            right: 0;
            margin-top: 1rem;
            background: #eee;
            padding: 2rem 1rem;
            border-radius: 3px;
            line-height: 100%;
            color: #555;
            font-size: 1.4rem;
            border: 1px solid #ddd;
            display: inline-flex !important;
            text-align: left;

            strong{
              display: none;
            }

            @include less-than($screen-sm){
              width: auto;
              min-width: 240%;
            }

          }
        }
      }
    }
  }

  .product-tabs {
    margin-top: 10rem;

    .horizontal-tabs {
      border: none;
    }

    .horizontal-tabs-list {
      @include tabs;
    }

    .horizontal-tabs-pane {
      padding: 0;
      border-top: 1px solid #e9e9e9;
      padding-top: 4rem;
      margin-top: -7px;
    }
  }

  .field-name-field-direction-of-use {
    font-size: 1.6rem;
    font-weight: 300;
    color: #5e5e5e;
    line-height: 1.7;
  }

  .field-name-field-related-products {
    > .field-items {
      @include grid-layout(4, 3rem);
      @include product;
    }
  }

  @include less-than($screen-lg) {
    .product-wrapper {
      flex-wrap: wrap;
    }
    .product-left {
      width: 100%;
      margin-bottom: 3rem;
    }
    .product-right {
      width: 100%;
    }

    .field-name-field-related-products {
      > .field-items {
        @include grid-layout(1, 0rem);

        .field-item {
          margin-bottom: 3rem;
        }
      }
    }
  }
}
