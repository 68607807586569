/*------------------------------------*\
    Mixin: News Item
\*------------------------------------*/
@mixin news {
  .views-row {
    .node-news {
      .field-name-title {
        margin: 1rem 0;
        h2 {
          font-size: 2rem;
          font-weight: 300;
          line-height: 1.5;
          a{
            &:hover{
              color: $color-primary;
            }
          }
        }
      }

      .field-name-field-summary {
        color: #555;
        font-size: 1.6rem;
        font-weight: 300;
        height: 7.5rem;
        overflow-y: hidden;
      }

      .field-name-node-link {
        a {
          @include link;
          &:after {
            content: ">";
            margin-top: 2px;
            margin-left: 1rem;
          }
        }
      }
      img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
      }
    }
  }
}
