/*------------------------------------*\
    Basic Mixins
\*------------------------------------*/
@mixin link {
  color: #4d87ca;
  transition: color 0.3s;

  &:hover {
    color: lighten(#4d87ca, 20%);
  }
}

@mixin grid-layout($cols, $gap) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat($cols, minmax(0, 1fr));

  @if $cols == 1 {
    .views-row {
      margin-bottom: 3rem;
    }
  }
}

@mixin squareImgBox {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  box-sizing: border-box;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin less-than($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin larger-than($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin myanimated( $duration: 300ms ) {
  transition: all 300ms ease;
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
}
