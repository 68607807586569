/*------------------------------------*\
    Mixin: Product Item
\*------------------------------------*/
@mixin product {
  > div {
    .node-product {
      padding: 1.4rem;
      border: 0.8px solid #f1f1f1;
      height: 100%;
      @apply tw-flex tw-flex-col tw-justify-between;
      @include myanimated;

      &:hover{
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
        h2 a{
          color: $color-orange;
        }
      }

      &:after {
        display: none;
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: contain;
      }

      h2 {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6;
        margin: 1rem 0;

        a{
          @include myanimated;
          &:hover{
            color: $color-orange;
          }
        }

      }

      .crossout-price{
        font-size: 1.8rem;
        text-decoration: line-through;
        color: #d43333;
      }


      .group-price-section {
        @apply tw-flex tw-flex-col;
      }

      .display-price {
        font-weight: 300;
        font-size: 1.5rem;
        color: #898989;
        text-align: left;

      }

      .form-actions {
        position: relative;
        margin-top: 1rem;

        button {
          width: 100%;
          @include btn($color-primary);
        }

        .alert{
          position: absolute;
          width: 100%;
          left: 0;
          bottom:0;
          background: $color-orange;
          color: $color-white;
          padding-right: 2.5rem;
          margin-bottom: 4rem;
          display: inline-flex;
          flex-wrap: wrap;
          text-align: left;
          font-size: 1.2rem;

          strong{
            display: none;
          }

          > .close{
            color: $color-white;
          }
        }
      }

      p,
      .form-item-qty,
      .form-item-qty ~ fieldset,
      .add-to-cart fieldset.panel,
      .field-name-koda-price-policy-sku {
        display: none;
      }
    }
  }
}
