.about{
  .grid-brands{
    display: grid;
    grid-gap: 1rem;

    @include larger-than($screen-sm) {
      grid-template-columns: repeat(5, 1fr);
    }

    img{
      width: 100%;
    }

  }
}
