.page-user{

  .profile{
    position: relative;
    width: 100%;
    padding: 2rem;
    border: 1px dashed #eee;

    h3{
      color: $color-primary;
      padding-bottom: 1rem;
      &:first-child{
        margin-top: 0;
      }
    }

  }

  /*
  ======================================================
   User Tab
  ======================================================
  */

  .tabs--primary{
    display: flex;
    flex-wrap: wrap;
    > li{
      display: flex;
      flex: auto;
      padding-left: 10px;
      padding-right: 10px;
      &.active{
        font-weight: bold;
      }
    }
  }

  /*
  ======================================================
   User Order
  ======================================================
  */
  .view-uc-orders-user{
    table{

      thead{
        tr{
          th{
            background-color:$color-primary;
            color: #FFF;
          }
        }
      }

      tbody{
        tr{
          &:not(.views-row-first){
            > td{
              border-top: 1px dashed #e2e8f0;
            }
          }
        }
      }

    }
  }


}
