/*------------------------------------*\
    Base
\*------------------------------------*/

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

:focus {
  outline: none;
}



