@tailwind base;
@tailwind components;
@tailwind utilities;
/*------------------------------------*\
    Variables
\*------------------------------------*/
/*------------------------------------*\
    Base
\*------------------------------------*/
html {
  scroll-behavior: smooth;
  font-size: 62.5%; }

:focus {
  outline: none; }

/*------------------------------------*\
    Typography
\*------------------------------------*/
html {
  font-family: Poppins, Arial, Helvetica, sans-serif; }

body {
  font-size: 1.6rem;
  color: #000; }

p {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 300;
  margin-bottom: 10px; }

h3 {
  margin: 1em 0 0.5em;
  font-size: 16pt; }

.region-content ol li, .region-content ul li {
  list-style: outside;
  font-weight: 300; }

.region-content ul {
  padding-left: 1em; }

.region-content ol > li {
  list-style-position: inside;
  display: list-item;
  list-style-type: decimal; }

.hide-info {
  display: none; }

/*------------------------------------*\
    Basic Mixins
\*------------------------------------*/
/*------------------------------------*\
    Mixin: Product Item
\*------------------------------------*/
/*------------------------------------*\
    Mixin: News Item
\*------------------------------------*/
/*------------------------------------*\
    Mixin: Tabs
\*------------------------------------*/
/*------------------------------------*\
    Mixin: Search Bar (search input)
\*------------------------------------*/
.koda-p__card {
  position: relative;
  width: 100%;
  height: 100%;
  @apply tw-flex tw-items-center tw-justify-center; }
  .koda-p__card.hero .koda-p__card--image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%; }
    .koda-p__card.hero .koda-p__card--image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .koda-p__card.hero .koda-p__card--body {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    @apply tw-flex tw-items-center tw-justify-center; }

@media screen and (max-width: 769px) {
  body {
    padding-top: 80px; } }

.tw-w-1-5 {
  @apply tw-w-1/4; }

.tw-container {
  width: 100%;
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px; }
  @media screen and (min-width: 1500px) {
    .tw-container {
      max-width: 1440px !important; } }
  @media screen and (max-width: 641px) {
    .tw-container {
      padding: 1rem 2rem; } }

.sidebar-first .content .sidebar {
  display: block; }

.sidebar-first .content .main-content {
  padding-left: 3rem; }

@media screen and (max-width: 641px) {
  .sidebar-first .content .main-content {
    padding-left: 0;
    width: 100% !important; } }

main {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.content#content-block {
  min-height: 40rem;
  margin: 0rem 0 10rem; }

@media screen and (max-width: 641px) {
  .content {
    margin: 0; } }

.content .breadcrumb {
  margin: .5rem 0 1.5rem; }

.content .sidebar {
  display: none; }
  .content .sidebar .view-footer-category-links {
    display: none; }

.region-sidebar-first {
  border-left: 1px solid #D7D7D7; }
  .region-sidebar-first ul li {
    margin: 0;
    padding: 0;
    display: block;
    float: none; }
  .region-sidebar-first ul.facetapi-facetapi-checkbox-links li a {
    padding: 0 !important; }
    .region-sidebar-first ul.facetapi-facetapi-checkbox-links li a:hover {
      padding: 0 !important; }
  .region-sidebar-first ul.facetapi-facetapi-checkbox-links li:hover {
    border: none !important; }
  .region-sidebar-first ul:not(.facetapi-facetapi-checkbox-links) li a {
    padding: 1rem 0 1rem 2rem;
    background: none;
    display: inline-block;
    vertical-align: middle;
    position: relative; }
    .region-sidebar-first ul:not(.facetapi-facetapi-checkbox-links) li a:hover {
      padding: 1rem 0 1rem 1.7rem; }
  .region-sidebar-first ul:not(.facetapi-facetapi-checkbox-links) li.active {
    background: #eee;
    border-left: 3px solid #4D87CA; }
  .region-sidebar-first ul:not(.facetapi-facetapi-checkbox-links) li:hover {
    border-left: 3px solid #4D87CA; }

@media screen and (max-width: 979px) {
  .desktop-only {
    display: none !important; } }

@media screen and (min-width: 980px) {
  .mobile-only {
    display: none !important; } }

/*------------------------------------*\
    Alert
\*------------------------------------*/
.region-alert {
  @apply tw-relative;
  @apply tw-w-full; }
  .region-alert > .block {
    @apply tw-flex;
    @apply tw-justify-center;
    @apply tw-items-center;
    @apply tw-text-white;
    @apply tw-bg-red-600;
    @apply tw-p-4; }
    .region-alert > .block p {
      @apply tw-text-center;
      @apply tw-mb-0;
      font-size: 1.4rem; }
      .region-alert > .block p::before {
        content: "\f071";
        font-family: 'FontAwesome';
        @apply tw-mr-2; }

/*------------------------------------*\
   Header
\*------------------------------------*/
header {
  font-size: 1.4rem;
  font-weight: 300; }
  @media screen and (max-width: 980px) {
    header {
      display: none; } }
  header .top_banner {
    background-color: #0a1a2a;
    height: 4rem;
    @apply tw-flex tw-items-center; }
    header .top_banner .region-top-banner {
      @apply tw-flex;
      @apply tw-justify-end; }
    header .top_banner .user-bar {
      @apply tw-flex tw-justify-end; }
      header .top_banner .user-bar ul.user-login a {
        display: flex;
        align-items: center; }
      header .top_banner .user-bar ul.user-login img {
        display: inline;
        width: 1.6rem;
        height: 1.6rem;
        object-fit: contain;
        margin-right: 0.5rem; }
      header .top_banner .user-bar a {
        padding: 0 2rem;
        color: #fff; }
        header .top_banner .user-bar a:hover {
          text-decoration: underline; }
  header .topmenu {
    position: relative; }
    header .topmenu .header-ribbons {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%; }
      @media screen and (max-width: 1025px) {
        header .topmenu .header-ribbons {
          display: none; } }
      header .topmenu .header-ribbons .ribbon {
        width: 44px;
        height: 100%;
        margin: 0;
        padding: 0;
        float: left;
        position: relative;
        transition: all 0.3s; }
        header .topmenu .header-ribbons .ribbon.ribbon-1 {
          background-color: #5075a0; }
          header .topmenu .header-ribbons .ribbon.ribbon-1:before {
            content: "";
            display: block;
            width: 44px;
            height: 40px;
            position: absolute;
            background: #5075a0;
            top: -40px;
            z-index: 99; }
        header .topmenu .header-ribbons .ribbon.ribbon-2 {
          background-color: #a2bcda; }
          header .topmenu .header-ribbons .ribbon.ribbon-2:before {
            content: "";
            display: block;
            width: 44px;
            height: 57px;
            position: absolute;
            background: #a2bcda;
            bottom: -57px;
            z-index: 99; }
        header .topmenu .header-ribbons .ribbon.ribbon-3 {
          background-color: #dedede; }
        header .topmenu .header-ribbons .ribbon.ribbon-4 {
          background-color: #bbbbbb; }
  header .region-topmenu {
    padding: 3rem 0;
    @apply tw-flex tw-w-full tw-items-center;
    /** Logo **/
    /** Search Form **/
    /** Shopping Camm-blockerrt **/ }
    header .region-topmenu .block-header-logo {
      flex-grow: 1;
      align-items: center;
      display: flex;
      padding-right: 5rem;
      transition: all 0.5s; }
      header .region-topmenu .block-header-logo img {
        height: 5rem;
        max-width: none; }
        @media screen and (max-width: 1025px) {
          header .region-topmenu .block-header-logo img {
            height: 4rem; } }
      @media screen and (max-width: 1645px) {
        header .region-topmenu .block-header-logo {
          padding-left: 17rem; } }
      @media screen and (max-width: 1024px) {
        header .region-topmenu .block-header-logo {
          padding-left: 0; } }
    header .region-topmenu .block-header-search {
      width: 65rem; }
      header .region-topmenu .block-header-search .views-exposed-widgets {
        position: relative;
        margin: 0;
        padding: 0; }
      header .region-topmenu .block-header-search .views-exposed-widget {
        padding: 0;
        margin: 0;
        width: 100%; }
      header .region-topmenu .block-header-search input.form-text {
        width: 100%;
        height: 5rem;
        text-indent: 1rem;
        border: 2px solid #f1f1f1;
        border-radius: 4px;
        color: #333; }
      header .region-topmenu .block-header-search .views-submit-button {
        position: absolute;
        padding: 0;
        margin: 0;
        height: 100%;
        right: 1rem;
        width: 24px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center; }
      header .region-topmenu .block-header-search button.form-submit {
        outline: none;
        border: none;
        background-image: url("/sites/all/themes/kodaweb-new/images/icons/search.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        color: transparent;
        width: 22px;
        height: 22px;
        margin: 0; }
    header .region-topmenu .block-uc-cart {
      height: 100%;
      position: relative;
      display: flex;
      width: 42px;
      margin-left: 2rem; }
      header .region-topmenu .block-uc-cart .cart-block-title-bar,
      header .region-topmenu .block-uc-cart .cart-block-items,
      header .region-topmenu .block-uc-cart .cart-block-summary-links,
      header .region-topmenu .block-uc-cart .cart-block-summary-total {
        display: none; }
      header .region-topmenu .block-uc-cart .block-title a span {
        background: none;
        background-image: url("/sites/all/themes/kodaweb-new/images/icons/cart.png");
        background-position: center;
        background-size: contain;
        width: 42px;
        height: 37px;
        background-repeat: no-repeat;
        display: inline-block; }
      header .region-topmenu .block-uc-cart .cart-block-summary-items {
        color: transparent; }
      header .region-topmenu .block-uc-cart .num-items {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #e98e05;
        text-align: center;
        line-height: 20px;
        border-radius: 100%;
        position: absolute;
        right: -5px;
        top: 8px;
        font-size: 12px;
        color: #fff; }

/*------------------------------------*\
    Footer
\*------------------------------------*/
.region-copyright {
  display: none; }

footer {
  background-color: #0a1a2a;
  color: #f1f1f1;
  padding: 5rem 0;
  font-weight: 300;
  font-size: 1.6rem; }
  footer .region-footer {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
    @media screen and (max-width: 769px) {
      footer .region-footer {
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: repeat(1, minmax(0, 1fr)); }
        footer .region-footer .views-row {
          margin-bottom: 3rem; } }
    footer .region-footer section .block-title {
      font-weight: 500;
      margin-bottom: 3rem; }
    footer .region-footer .view-content {
      display: flex;
      flex-direction: column; }
    footer .region-footer .views-row,
    footer .region-footer li,
    footer .region-footer > div {
      margin-bottom: 1rem; }
      footer .region-footer .views-row a:hover,
      footer .region-footer li a:hover,
      footer .region-footer > div a:hover {
        text-decoration: underline; }
    footer .region-footer .social-media-link {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      display: inline-block;
      background-color: #2b8ec6;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      color: #0A1A2A;
      transition: all 0.3s;
      font-size: 20px; }
      @media screen and (max-width: 1025px) {
        footer .region-footer .social-media-link {
          margin-right: 0; } }
      footer .region-footer .social-media-link:hover {
        background-color: #fff;
        color: #0A1A2A; }

/*------------------------------------*\
    Components: alert
\*------------------------------------*/
.alert {
  position: relative;
  background: #4d87ca;
  border-radius: 4px;
  padding: 1rem;
  color: #fff;
  font-size: 300;
  display: flex;
  align-items: center;
  margin: 1rem 0; }
  .alert a.close {
    position: absolute;
    right: 1rem;
    font-size: 2rem;
    transition: all 0.3s; }
    .alert a.close:hover {
      color: #000; }
  .alert.alert-success {
    background-color: #86bc86; }
  .alert.alert-danger {
    background-color: #e48953; }
  .alert.alert-warning {
    background-color: #eebe76; }

/*------------------------------------*\
    Component: Breadcrumb
\*------------------------------------*/
.region-breadcrumbs {
  margin: 2rem 0;
  font-size: 1.4rem;
  font-weight: 300; }
  .region-breadcrumbs .easy-breadcrumb_segment-separator {
    color: #999; }
  .region-breadcrumbs a.easy-breadcrumb_segment {
    color: #4d87ca;
    transition: color 0.3s; }
    .region-breadcrumbs a.easy-breadcrumb_segment:hover {
      color: #9cbce1; }

/*------------------------------------*\
    Component: Burger Menu
\*------------------------------------*/
.hamburger-menu {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger-menu:hover {
  opacity: 0.7; }

.hamburger.is-active:hover {
  opacity: 0.7; }

.hamburger-menu.is-active .hamburger-inner,
.hamburger-menu.is-active .hamburger-inner::before,
.hamburger-menu.is-active .hamburger-inner::after {
  background-color: #000; }

.hamburger-box {
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 35px;
  height: 2px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block; }

.hamburger-inner::before {
  top: -10px; }

.hamburger-inner::after {
  bottom: -10px; }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*------------------------------------*\
    Component: Button
\*------------------------------------*/
.btn-primary {
  background-color: #4d87ca;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  font-size: 1.4rem;
  text-align: center; }

.btn-primary:hover, .btn-primary:active {
  background-color: #74a1d6; }

.btn-secondary {
  background-color: #E7760E;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  font-size: 1.4rem;
  text-align: center; }

.btn-secondary:hover, .btn-secondary:active {
  background-color: #f39035; }

/*------------------------------------*\
    Component: Mega Menu
\*------------------------------------*/
.mega_menu {
  background-color: #203c5d; }
  .mega_menu .tb-megamenu {
    background-color: #203c5d; }
  .mega_menu .tb-megamenu-block {
    margin: 0; }
  .mega_menu .dropdown-menu,
  .mega_menu .caret {
    border: none; }
  .mega_menu .tb-megamenu-nav {
    display: flex;
    justify-content: flex-end; }
    .mega_menu .tb-megamenu-nav > li {
      position: static !important; }
      .mega_menu .tb-megamenu-nav > li > a {
        transition: all 0.2s;
        background-color: #1a3452 !important;
        font-size: 1.8rem;
        font-weight: 500; }
        .mega_menu .tb-megamenu-nav > li > a:hover, .mega_menu .tb-megamenu-nav > li > a:active {
          color: #fff; }
      .mega_menu .tb-megamenu-nav > li:hover > a {
        background-color: #fff !important;
        color: #1a3452 !important; }
      .mega_menu .tb-megamenu-nav > li:first-child a {
        color: transparent;
        background-image: url(/sites/all/themes/kodaweb-new/images/icons/home.svg);
        background-repeat: no-repeat;
        background-position: center; }
      .mega_menu .tb-megamenu-nav > li:first-child:hover > a {
        color: transparent !important;
        background-image: url(/sites/all/themes/kodaweb-new/images/icons/home-hover.svg); }
    .mega_menu .tb-megamenu-nav .mega-dropdown-menu {
      width: 100% !important;
      /** Custom class name for navigation-queues assigned **/
      /** Sub menu Item**/ }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu a {
        transition: all 300ms ease;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .navigation-queues h2 {
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 1rem 0 0 0;
        font-size: 2.5rem;
        transition: all 300ms ease;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .navigation-queues img {
        height: 203px;
        width: 100%;
        border: 4px solid #FFF;
        object-fit: cover;
        transition: all 300ms ease;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .navigation-queues .views-row:hover h2 a {
        color: #4d87ca; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .navigation-queues .views-row:hover img {
        border-color: #4d87ca; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .view-content {
        display: flex;
        padding: 1.5rem; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .submenu--item {
        @apply tw-w-1/5;
        padding: 1rem; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .submenu--item .field-content {
          height: 0;
          overflow: hidden;
          padding-top: 100%;
          box-sizing: border-box;
          position: relative; }
          .mega_menu .tb-megamenu-nav .mega-dropdown-menu .submenu--item .field-content img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .submenu--item img {
          transition: all 0.3s; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .submenu--item .views-field-name {
          font-size: 2rem;
          text-align: center;
          margin-top: 1rem; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .submenu--item:hover img {
          transform: scale(1.03); }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .submenu--item:hover .views-field-name a {
          color: #1a1a1a; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu h3 {
        display: none; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-left {
        width: 40%; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-left img {
          width: 100%;
          height: auto;
          object-fit: cover;
          max-width: none;
          border: 4px solid #FFF;
          transition: all 300ms ease;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-left a:hover img {
          border: 4px solid #4d87ca; }
      .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right {
        width: 60%;
        padding: 0 2rem; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-title h2,
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-category-custom-header-link h2 {
          font-size: 2.5rem; }
          .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-title h2 a,
          .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-category-custom-header-link h2 a {
            padding-left: 0;
            padding-right: 0;
            transition: all 300ms ease;
            -webkit-transition: all 300ms ease;
            -moz-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease; }
            .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-title h2 a:hover,
            .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-category-custom-header-link h2 a:hover {
              color: #4d87ca; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .taxonomy-term-description {
          margin: 1rem 0; }
          .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .taxonomy-term-description p {
            font-weight: 300; }
        .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-category-to-products a {
          padding: 0;
          color: #2b8ec6; }
          .mega_menu .tb-megamenu-nav .mega-dropdown-menu .group-right .field-name-category-to-products a:before {
            content: ">";
            font-size: 1.6rem;
            margin-right: 1rem; }

/*------------------------------------*\
    Component: Mobile Header
\*------------------------------------*/
.mobile_header {
  display: none;
  width: 100vw;
  height: 7rem;
  padding: 1rem;
  position: fixed;
  top: 0;
  z-index: 99;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 4px #eee;
  /*------------------------------------*\
    Block Cart
  \*------------------------------------*/ }
  @media screen and (max-width: 980px) {
    .mobile_header {
      display: flex; } }
  .mobile_header img {
    margin: 1rem;
    height: 3rem; }
  .mobile_header .hamburger-menu:focus {
    outline: none; }
  .mobile_header .region {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%; }
    .mobile_header .region .mobile-header-logo {
      margin-right: auto; }
  .mobile_header .block-uc-cart {
    height: 100%;
    position: relative;
    display: flex;
    width: 42px;
    margin-left: 0.5rem; }
    .mobile_header .block-uc-cart .cart-block-title-bar,
    .mobile_header .block-uc-cart .cart-block-items,
    .mobile_header .block-uc-cart .cart-block-summary-links,
    .mobile_header .block-uc-cart .cart-block-summary-total {
      display: none; }
    .mobile_header .block-uc-cart .block-title a span {
      background: none;
      background-image: url(/sites/all/themes/kodaweb-new/images/icons/cart.png);
      background-position: center;
      background-size: contain;
      width: 42px;
      height: 37px;
      background-repeat: no-repeat;
      display: inline-block; }
      @media screen and (max-width: 1025px) {
        .mobile_header .block-uc-cart .block-title a span {
          width: 30px;
          height: 30px; } }
    .mobile_header .block-uc-cart .cart-block-summary-items {
      color: transparent; }
    .mobile_header .block-uc-cart .num-items {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #e98e05;
      text-align: center;
      line-height: 20px;
      border-radius: 100%;
      position: absolute;
      right: -5px;
      top: 8px;
      font-size: 12px;
      color: #fff; }

/*------------------------------------*\
    Component: Mobile Menu
\*------------------------------------*/
#mmenu_left {
  /** Menu List **/ }
  #mmenu_left .block-header-logo {
    display: none; }
  #mmenu_left .mm-list li {
    border: none; }
  #mmenu_left .mm-list span,
  #mmenu_left .mm-list a {
    padding: 0; }
  #mmenu_left ul.mm-panel {
    padding-left: 2rem;
    padding-right: 2rem; }
  #mmenu_left .user-bar {
    margin-top: 2rem;
    display: flex;
    flex-direction: column; }
    #mmenu_left .user-bar ul {
      width: 100%;
      display: flex;
      justify-content: space-around; }
      #mmenu_left .user-bar ul.user-login {
        justify-content: center; }
        #mmenu_left .user-bar ul.user-login li {
          margin-right: 1rem; }
      #mmenu_left .user-bar ul img {
        width: 20px;
        height: 20px;
        margin-right: 1rem; }
      #mmenu_left .user-bar ul a {
        display: flex; }
  #mmenu_left .block-header-search .views-exposed-widgets {
    position: relative;
    margin: 0;
    padding: 0; }
  #mmenu_left .block-header-search .views-exposed-widget {
    padding: 0;
    margin: 0;
    width: 100%; }
  #mmenu_left .block-header-search input.form-text {
    width: 100%;
    height: 5rem;
    text-indent: 1rem;
    border: 2px solid #f1f1f1;
    border-radius: 4px;
    color: #333; }
  #mmenu_left .block-header-search .views-submit-button {
    position: absolute;
    padding: 0;
    margin: 0;
    height: 100%;
    right: 1rem;
    width: 24px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center; }
  #mmenu_left .block-header-search button.form-submit {
    outline: none;
    border: none;
    background-image: url("/sites/all/themes/kodaweb-new/images/icons/search.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    color: transparent;
    width: 22px;
    height: 22px;
    margin: 0; }
  #mmenu_left .block-header-search input {
    width: 100% !important; }
  #mmenu_left .block-uc-cart {
    margin: 2rem 0; }
    #mmenu_left .block-uc-cart .cart-block-title-bar,
    #mmenu_left .block-uc-cart p.cart-block-items {
      display: none; }
    #mmenu_left .block-uc-cart .cart-block-summary {
      width: 100%; }
      #mmenu_left .block-uc-cart .cart-block-summary .cart-block-summary-links ul.links {
        display: flex;
        justify-content: space-between;
        color: #e98e05;
        border-top: 1px solid #c4ccda;
        border-bottom: 1px solid #c4ccda;
        margin-top: 1rem;
        padding: 1rem 0; }
      #mmenu_left .block-uc-cart .cart-block-summary .cart-block-summary-items::before {
        content: '\f291';
        font-family: "Font Awesome 5 Pro";
        margin-right: 1rem; }
      #mmenu_left .block-uc-cart .cart-block-summary .cart-block-summary-total {
        text-align: right;
        color: #fff; }
        #mmenu_left .block-uc-cart .cart-block-summary .cart-block-summary-total label {
          margin-bottom: 0; }
  #mmenu_left .top-banner-menu li {
    border-bottom: 1px solid #25282f;
    border-top: 1px solid #444141; }
    #mmenu_left .top-banner-menu li a {
      padding: 1rem 0; }
  #mmenu_left .mm-list.mmenu-mm-list-level-1 li {
    margin-bottom: 0.5rem; }
  #mmenu_left .mm-list.mmenu-mm-list-level-1 > li a i {
    display: none; }
  #mmenu_left .mm-list.mmenu-mm-list-level-2, #mmenu_left .mm-list.mmenu-mm-list-level-3 {
    padding-top: 2rem; }
    #mmenu_left .mm-list.mmenu-mm-list-level-2 .mm-subtitle, #mmenu_left .mm-list.mmenu-mm-list-level-3 .mm-subtitle {
      margin-bottom: 1rem; }
    #mmenu_left .mm-list.mmenu-mm-list-level-2 .mm-subclose, #mmenu_left .mm-list.mmenu-mm-list-level-3 .mm-subclose {
      position: relative;
      color: #fff;
      font-size: 18px;
      padding: 4px 0;
      margin: 0;
      background: none; }
      #mmenu_left .mm-list.mmenu-mm-list-level-2 .mm-subclose::before, #mmenu_left .mm-list.mmenu-mm-list-level-3 .mm-subclose::before {
        content: '\f104';
        position: absolute;
        font-family: 'FontAwesome';
        left: 0;
        top: 0;
        height: 100%;
        text-indent: 0;
        transform: rotate(0);
        border: none !important;
        margin: 0;
        display: flex;
        align-items: center; }

/*------------------------------------*\
    Component: Slick Dots
\*------------------------------------*/
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #4d87ca;
  border-radius: 100%; }

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black; }

/*------------------------------------*\
    Component: Top Image Region
\*------------------------------------*/
.topimg {
  overflow: hidden; }
  .topimg .view-home-slide-show {
    position: relative; }
    .topimg .view-home-slide-show .slick:focus {
      outline: none; }
    .topimg .view-home-slide-show .slick img {
      width: 100%;
      height: 45rem;
      object-fit: cover; }
    .topimg .view-home-slide-show .home-slide-show-title {
      position: absolute;
      width: 100%;
      bottom: 5rem;
      color: #fff; }
      .topimg .view-home-slide-show .home-slide-show-title .field-name-title {
        font-size: 5rem;
        text-transform: uppercase; }
    .topimg .view-home-slide-show .slick .slide__content .home-slide-show-title {
      z-index: 999; }
    .topimg .view-home-slide-show .slick .slide__content::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); }
    .topimg .view-home-slide-show .slick__slider {
      z-index: 50;
      position: relative; }
    .topimg .view-home-slide-show .slick__arrow {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 50;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease; }
      .topimg .view-home-slide-show .slick__arrow .slick-arrow {
        transition: all 300ms ease;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        z-index: 99;
        width: 6rem;
        display: flex;
        outline: none;
        justify-content: center;
        align-items: center; }
        .topimg .view-home-slide-show .slick__arrow .slick-arrow:hover::before {
          color: #FFF; }
        .topimg .view-home-slide-show .slick__arrow .slick-arrow::before {
          font-family: 'FontAwesome';
          font-size: 6rem;
          line-height: 1;
          color: #a2bcda;
          font-weight: normal;
          transition: all 300ms ease;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease; }
        .topimg .view-home-slide-show .slick__arrow .slick-arrow.slick-prev::before {
          content: "\f104"; }
        .topimg .view-home-slide-show .slick__arrow .slick-arrow.slick-next::before {
          content: "\f105"; }
      @media screen and (min-width: 769px) {
        .topimg .view-home-slide-show .slick__arrow:hover .slick-arrow {
          opacity: 1; }
        .topimg .view-home-slide-show .slick__arrow .slick-arrow {
          opacity: 0.2; } }

.topimg-container {
  position: relative; }
  .topimg-container img {
    width: 100%;
    height: 32rem;
    object-fit: cover; }
  .topimg-container h1 {
    position: absolute;
    bottom: 3rem;
    color: #fff;
    font-weight: 500;
    font-size: 6rem; }
  @media screen and (max-width: 769px) {
    .topimg-container img {
      height: 20rem; }
    .topimg-container h1 {
      font-size: 4rem; } }

/*------------------------------------*\
    Component: Webforms
\*------------------------------------*/
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

.form-required {
  color: #e53e3e; }

.panel-body {
  padding: 25px;
  margin-bottom: 0.5em;
  float: left;
  width: 100%; }

.panel-default > .panel-heading {
  color: #ffffff;
  background-color: #0a1a2a;
  border: none !important; }

.panel-heading {
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  width: 100%;
  font-size: 21px;
  line-height: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit; }

form .form-control {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px; }

form button, form .btn-default, form .btn {
  font-size: 1.875rem;
  background-color: #4d87ca;
  color: #FFF;
  font-weight: 600;
  border-color: #295588;
  outline: none;
  padding: 10px 15px; }

.help-block {
  color: #b31616;
  font-size: 12px; }

.glyphicon-question-sign:before {
  content: "\e085"; }

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none; }

/*------------------------------------*\
  Pls. do not hardcode webform ID's because 
  it will not work on the live site. live site has diff ID's
  add .webform-cols to your form wrapper instead
\*------------------------------------*/
.webform-cols .file_uploads .form-file,
.file_uploads .form-file,
.webform-cols .webform-component-file .form-file,
#webform-client-form-16223 .file_uploads .form-file,
#webform-client-form-16224 .file_uploads .form-file,
#webform-client-form-16225 .file_uploads .form-file,
#webform-client-form-16226 .webform-component-file .form-file,
#webform-client-form-30 .webform-component-file .form-file {
  margin-right: 20px !important;
  width: 73% !important; }

.webform-submit {
  font-size: 1.5rem;
  background-color: #629020 !important;
  color: #FFF;
  font-weight: 600;
  border-color: #629020 !important;
  outline: none; }

.form-actions {
  text-align: center;
  margin: 0 auto; }

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0; }

.checkbox input[type=checkbox],
.radio input[type=radio] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px;
  line-height: normal; }

.checkbox, .radio {
  position: relative;
  display: block;
  margin-bottom: 10px; }

.checkbox label, .radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer; }

.product-replacement-request-form .webform-client-form .form-item,
.soil-guard-service-request-form .webform-client-form .form-item,
.leather-guard-service-request-form .webform-client-form .form-item {
  margin-top: 10px;
  float: left;
  padding: 0 30px 0 0; }

.product-replacement-request-form .webform-client-form,
.soil-guard-service-request-form .webform-client-form,
.leather-guard-service-request-form .webform-client-form {
  display: block; }
  .product-replacement-request-form .webform-client-form .form-item,
  .soil-guard-service-request-form .webform-client-form .form-item,
  .leather-guard-service-request-form .webform-client-form .form-item {
    margin-top: 10px;
    float: left;
    padding: 0 30px 0 0; }
    @media screen and (max-width: 767px) {
      .product-replacement-request-form .webform-client-form .form-item,
      .soil-guard-service-request-form .webform-client-form .form-item,
      .leather-guard-service-request-form .webform-client-form .form-item {
        width: 100%;
        float: none;
        padding: 0 0 0 0; } }
  .product-replacement-request-form .webform-client-form .form-date .form-item,
  .soil-guard-service-request-form .webform-client-form .form-date .form-item,
  .leather-guard-service-request-form .webform-client-form .form-date .form-item {
    padding-right: 0; }
  .product-replacement-request-form .webform-client-form #edit-submitted-service-request-details-reason-for-service-request .form-item,
  .soil-guard-service-request-form .webform-client-form #edit-submitted-service-request-details-reason-for-service-request .form-item,
  .leather-guard-service-request-form .webform-client-form #edit-submitted-service-request-details-reason-for-service-request .form-item {
    width: 100%;
    margin-bottom: 0; }

/*------------------------------------*\
  Pls. do not hardcode webform ID's because 
  it will not work on the live site. live site has diff ID's
  add .webform-cols to your form wrapper instead
\*------------------------------------*/
.webform-cols .tabledrag-toggle-weight-wrapper,
#webform-client-form-30 .tabledrag-toggle-weight-wrapper,
#webform-client-form-16226 .tabledrag-toggle-weight-wrapper,
#webform-client-form-16225 .tabledrag-toggle-weight-wrapper,
#webform-client-form-16224 .tabledrag-toggle-weight-wrapper,
#webform-client-form-16223 .tabledrag-toggle-weight-wrapper {
  display: none; }

.webform-cols .form-group,
#webform-client-form-30 .form-group,
#webform-client-form-16226 .form-group,
#webform-client-form-16225 .form-group,
#webform-client-form-16224 .form-group,
#webform-client-form-16223 .form-group {
  margin-bottom: 15px;
  width: 46%;
  float: left;
  margin-right: 30px; }
  @media (max-width: 768px) {
    .webform-cols .form-group,
    #webform-client-form-30 .form-group,
    #webform-client-form-16226 .form-group,
    #webform-client-form-16225 .form-group,
    #webform-client-form-16224 .form-group,
    #webform-client-form-16223 .form-group {
      width: 100%; } }

.webform-cols .leather-guard-service-request-form .webform-client-form .form-item,
#webform-client-form-30 .leather-guard-service-request-form .webform-client-form .form-item,
#webform-client-form-16226 .leather-guard-service-request-form .webform-client-form .form-item,
#webform-client-form-16225 .leather-guard-service-request-form .webform-client-form .form-item,
#webform-client-form-16224 .leather-guard-service-request-form .webform-client-form .form-item,
#webform-client-form-16223 .leather-guard-service-request-form .webform-client-form .form-item {
  width: 100% !important;
  padding: 0;
  margin: 0; }

.webform-cols .file_uploads,
.webform-cols .webform-component-file,
#webform-client-form-30 .file_uploads,
#webform-client-form-30 .webform-component-file,
#webform-client-form-16226 .file_uploads,
#webform-client-form-16226 .webform-component-file,
#webform-client-form-16225 .file_uploads,
#webform-client-form-16225 .webform-component-file,
#webform-client-form-16224 .file_uploads,
#webform-client-form-16224 .webform-component-file,
#webform-client-form-16223 .file_uploads,
#webform-client-form-16223 .webform-component-file {
  margin-bottom: 15px;
  width: 46%;
  float: left;
  margin-right: 30px; }
  .webform-cols .file_uploads .panel-body,
  .webform-cols .webform-component-file .panel-body,
  #webform-client-form-30 .file_uploads .panel-body,
  #webform-client-form-30 .webform-component-file .panel-body,
  #webform-client-form-16226 .file_uploads .panel-body,
  #webform-client-form-16226 .webform-component-file .panel-body,
  #webform-client-form-16225 .file_uploads .panel-body,
  #webform-client-form-16225 .webform-component-file .panel-body,
  #webform-client-form-16224 .file_uploads .panel-body,
  #webform-client-form-16224 .webform-component-file .panel-body,
  #webform-client-form-16223 .file_uploads .panel-body,
  #webform-client-form-16223 .webform-component-file .panel-body {
    padding: 0; }
  .webform-cols .file_uploads label,
  .webform-cols .webform-component-file label,
  #webform-client-form-30 .file_uploads label,
  #webform-client-form-30 .webform-component-file label,
  #webform-client-form-16226 .file_uploads label,
  #webform-client-form-16226 .webform-component-file label,
  #webform-client-form-16225 .file_uploads label,
  #webform-client-form-16225 .webform-component-file label,
  #webform-client-form-16224 .file_uploads label,
  #webform-client-form-16224 .webform-component-file label,
  #webform-client-form-16223 .file_uploads label,
  #webform-client-form-16223 .webform-component-file label {
    font-size: 14px; }
  .webform-cols .file_uploads .form-file,
  .webform-cols .webform-component-file .form-file,
  #webform-client-form-30 .file_uploads .form-file,
  #webform-client-form-30 .webform-component-file .form-file,
  #webform-client-form-16226 .file_uploads .form-file,
  #webform-client-form-16226 .webform-component-file .form-file,
  #webform-client-form-16225 .file_uploads .form-file,
  #webform-client-form-16225 .webform-component-file .form-file,
  #webform-client-form-16224 .file_uploads .form-file,
  #webform-client-form-16224 .webform-component-file .form-file,
  #webform-client-form-16223 .file_uploads .form-file,
  #webform-client-form-16223 .webform-component-file .form-file {
    margin-right: 20px; }
  .webform-cols .file_uploads .form-item,
  .webform-cols .webform-component-file .form-item,
  #webform-client-form-30 .file_uploads .form-item,
  #webform-client-form-30 .webform-component-file .form-item,
  #webform-client-form-16226 .file_uploads .form-item,
  #webform-client-form-16226 .webform-component-file .form-item,
  #webform-client-form-16225 .file_uploads .form-item,
  #webform-client-form-16225 .webform-component-file .form-item,
  #webform-client-form-16224 .file_uploads .form-item,
  #webform-client-form-16224 .webform-component-file .form-item,
  #webform-client-form-16223 .file_uploads .form-item,
  #webform-client-form-16223 .webform-component-file .form-item {
    width: 100%; }
  .webform-cols .file_uploads .panel-heading,
  .webform-cols .webform-component-file .panel-heading,
  #webform-client-form-30 .file_uploads .panel-heading,
  #webform-client-form-30 .webform-component-file .panel-heading,
  #webform-client-form-16226 .file_uploads .panel-heading,
  #webform-client-form-16226 .webform-component-file .panel-heading,
  #webform-client-form-16225 .file_uploads .panel-heading,
  #webform-client-form-16225 .webform-component-file .panel-heading,
  #webform-client-form-16224 .file_uploads .panel-heading,
  #webform-client-form-16224 .webform-component-file .panel-heading,
  #webform-client-form-16223 .file_uploads .panel-heading,
  #webform-client-form-16223 .webform-component-file .panel-heading {
    color: #333333;
    background: none !important;
    padding-left: 0;
    padding-bottom: 0;
    font-weight: 700; }
  .webform-cols .file_uploads table,
  .webform-cols .webform-component-file table,
  #webform-client-form-30 .file_uploads table,
  #webform-client-form-30 .webform-component-file table,
  #webform-client-form-16226 .file_uploads table,
  #webform-client-form-16226 .webform-component-file table,
  #webform-client-form-16225 .file_uploads table,
  #webform-client-form-16225 .webform-component-file table,
  #webform-client-form-16224 .file_uploads table,
  #webform-client-form-16224 .webform-component-file table,
  #webform-client-form-16223 .file_uploads table,
  #webform-client-form-16223 .webform-component-file table {
    font-size: 14px;
    width: 100%;
    background: #fafafa; }
    .webform-cols .file_uploads table th,
    .webform-cols .webform-component-file table th,
    #webform-client-form-30 .file_uploads table th,
    #webform-client-form-30 .webform-component-file table th,
    #webform-client-form-16226 .file_uploads table th,
    #webform-client-form-16226 .webform-component-file table th,
    #webform-client-form-16225 .file_uploads table th,
    #webform-client-form-16225 .webform-component-file table th,
    #webform-client-form-16224 .file_uploads table th,
    #webform-client-form-16224 .webform-component-file table th,
    #webform-client-form-16223 .file_uploads table th,
    #webform-client-form-16223 .webform-component-file table th {
      text-align: left;
      background: #eee; }
    .webform-cols .file_uploads table .btn,
    .webform-cols .webform-component-file table .btn,
    #webform-client-form-30 .file_uploads table .btn,
    #webform-client-form-30 .webform-component-file table .btn,
    #webform-client-form-16226 .file_uploads table .btn,
    #webform-client-form-16226 .webform-component-file table .btn,
    #webform-client-form-16225 .file_uploads table .btn,
    #webform-client-form-16225 .webform-component-file table .btn,
    #webform-client-form-16224 .file_uploads table .btn,
    #webform-client-form-16224 .webform-component-file table .btn,
    #webform-client-form-16223 .file_uploads table .btn,
    #webform-client-form-16223 .webform-component-file table .btn {
      font-size: 1.4rem;
      padding: 4px 5px;
      border: none;
      background-color: #333; }
  .webform-cols .file_uploads .alert.alert-danger,
  .webform-cols .webform-component-file .alert.alert-danger,
  #webform-client-form-30 .file_uploads .alert.alert-danger,
  #webform-client-form-30 .webform-component-file .alert.alert-danger,
  #webform-client-form-16226 .file_uploads .alert.alert-danger,
  #webform-client-form-16226 .webform-component-file .alert.alert-danger,
  #webform-client-form-16225 .file_uploads .alert.alert-danger,
  #webform-client-form-16225 .webform-component-file .alert.alert-danger,
  #webform-client-form-16224 .file_uploads .alert.alert-danger,
  #webform-client-form-16224 .webform-component-file .alert.alert-danger,
  #webform-client-form-16223 .file_uploads .alert.alert-danger,
  #webform-client-form-16223 .webform-component-file .alert.alert-danger {
    font-size: 12px; }
  @media (max-width: 768px) {
    .webform-cols .file_uploads,
    .webform-cols .webform-component-file,
    #webform-client-form-30 .file_uploads,
    #webform-client-form-30 .webform-component-file,
    #webform-client-form-16226 .file_uploads,
    #webform-client-form-16226 .webform-component-file,
    #webform-client-form-16225 .file_uploads,
    #webform-client-form-16225 .webform-component-file,
    #webform-client-form-16224 .file_uploads,
    #webform-client-form-16224 .webform-component-file,
    #webform-client-form-16223 .file_uploads,
    #webform-client-form-16223 .webform-component-file {
      width: 100%; } }

.webform-cols .form-item-submitted-title-product-purchase-date-day,
.webform-cols .form-item-submitted-title-product-purchase-date-month,
.webform-cols .form-item-submitted-title-product-purchase-date-year,
.webform-cols .form-item-submitted-product-details-product-purchase-date-day,
.webform-cols .form-item-submitted-product-details-product-purchase-date-month,
.webform-cols .form-item-submitted-product-details-product-purchase-date-year,
.webform-cols .form-item-submitted-product-details-product-purchase-date-day,
.webform-cols .form-item-submitted-product-details-product-purchase-date-month,
.webform-cols .form-item-submitted-product-details-product-purchase-date-year,
.webform-cols .form-item-submitted-product-purchase-date-day,
.webform-cols .form-item-submitted-product-purchase-date-month,
.webform-cols .form-item-submitted-product-purchase-date-year,
.webform-cols .form-item-submitted-what-date-did-the-incident-occur-day,
.webform-cols .form-item-submitted-what-date-did-the-incident-occur-month,
.webform-cols .form-item-submitted-what-date-did-the-incident-occur-year,
.webform-cols .form-date .form-group,
#webform-client-form-30 .form-item-submitted-title-product-purchase-date-day,
#webform-client-form-30 .form-item-submitted-title-product-purchase-date-month,
#webform-client-form-30 .form-item-submitted-title-product-purchase-date-year,
#webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-30 .form-item-submitted-product-purchase-date-day,
#webform-client-form-30 .form-item-submitted-product-purchase-date-month,
#webform-client-form-30 .form-item-submitted-product-purchase-date-year,
#webform-client-form-30 .form-item-submitted-what-date-did-the-incident-occur-day,
#webform-client-form-30 .form-item-submitted-what-date-did-the-incident-occur-month,
#webform-client-form-30 .form-item-submitted-what-date-did-the-incident-occur-year,
#webform-client-form-30 .form-date .form-group,
#webform-client-form-16226 .form-item-submitted-title-product-purchase-date-day,
#webform-client-form-16226 .form-item-submitted-title-product-purchase-date-month,
#webform-client-form-16226 .form-item-submitted-title-product-purchase-date-year,
#webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16226 .form-item-submitted-product-purchase-date-day,
#webform-client-form-16226 .form-item-submitted-product-purchase-date-month,
#webform-client-form-16226 .form-item-submitted-product-purchase-date-year,
#webform-client-form-16226 .form-item-submitted-what-date-did-the-incident-occur-day,
#webform-client-form-16226 .form-item-submitted-what-date-did-the-incident-occur-month,
#webform-client-form-16226 .form-item-submitted-what-date-did-the-incident-occur-year,
#webform-client-form-16226 .form-date .form-group,
#webform-client-form-16225 .form-item-submitted-title-product-purchase-date-day,
#webform-client-form-16225 .form-item-submitted-title-product-purchase-date-month,
#webform-client-form-16225 .form-item-submitted-title-product-purchase-date-year,
#webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16225 .form-item-submitted-product-purchase-date-day,
#webform-client-form-16225 .form-item-submitted-product-purchase-date-month,
#webform-client-form-16225 .form-item-submitted-product-purchase-date-year,
#webform-client-form-16225 .form-item-submitted-what-date-did-the-incident-occur-day,
#webform-client-form-16225 .form-item-submitted-what-date-did-the-incident-occur-month,
#webform-client-form-16225 .form-item-submitted-what-date-did-the-incident-occur-year,
#webform-client-form-16225 .form-date .form-group,
#webform-client-form-16224 .form-item-submitted-title-product-purchase-date-day,
#webform-client-form-16224 .form-item-submitted-title-product-purchase-date-month,
#webform-client-form-16224 .form-item-submitted-title-product-purchase-date-year,
#webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16224 .form-item-submitted-product-purchase-date-day,
#webform-client-form-16224 .form-item-submitted-product-purchase-date-month,
#webform-client-form-16224 .form-item-submitted-product-purchase-date-year,
#webform-client-form-16224 .form-item-submitted-what-date-did-the-incident-occur-day,
#webform-client-form-16224 .form-item-submitted-what-date-did-the-incident-occur-month,
#webform-client-form-16224 .form-item-submitted-what-date-did-the-incident-occur-year,
#webform-client-form-16224 .form-date .form-group,
#webform-client-form-16223 .form-item-submitted-title-product-purchase-date-day,
#webform-client-form-16223 .form-item-submitted-title-product-purchase-date-month,
#webform-client-form-16223 .form-item-submitted-title-product-purchase-date-year,
#webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-day,
#webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-month,
#webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-year,
#webform-client-form-16223 .form-item-submitted-product-purchase-date-day,
#webform-client-form-16223 .form-item-submitted-product-purchase-date-month,
#webform-client-form-16223 .form-item-submitted-product-purchase-date-year,
#webform-client-form-16223 .form-item-submitted-what-date-did-the-incident-occur-day,
#webform-client-form-16223 .form-item-submitted-what-date-did-the-incident-occur-month,
#webform-client-form-16223 .form-item-submitted-what-date-did-the-incident-occur-year,
#webform-client-form-16223 .form-date .form-group {
  width: 100px !important;
  float: left;
  margin-right: 15px; }
  @media (max-width: 480px) {
    .webform-cols .form-item-submitted-title-product-purchase-date-day,
    .webform-cols .form-item-submitted-title-product-purchase-date-month,
    .webform-cols .form-item-submitted-title-product-purchase-date-year,
    .webform-cols .form-item-submitted-product-details-product-purchase-date-day,
    .webform-cols .form-item-submitted-product-details-product-purchase-date-month,
    .webform-cols .form-item-submitted-product-details-product-purchase-date-year,
    .webform-cols .form-item-submitted-product-details-product-purchase-date-day,
    .webform-cols .form-item-submitted-product-details-product-purchase-date-month,
    .webform-cols .form-item-submitted-product-details-product-purchase-date-year,
    .webform-cols .form-item-submitted-product-purchase-date-day,
    .webform-cols .form-item-submitted-product-purchase-date-month,
    .webform-cols .form-item-submitted-product-purchase-date-year,
    .webform-cols .form-item-submitted-what-date-did-the-incident-occur-day,
    .webform-cols .form-item-submitted-what-date-did-the-incident-occur-month,
    .webform-cols .form-item-submitted-what-date-did-the-incident-occur-year,
    .webform-cols .form-date .form-group,
    #webform-client-form-30 .form-item-submitted-title-product-purchase-date-day,
    #webform-client-form-30 .form-item-submitted-title-product-purchase-date-month,
    #webform-client-form-30 .form-item-submitted-title-product-purchase-date-year,
    #webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-30 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-30 .form-item-submitted-product-purchase-date-day,
    #webform-client-form-30 .form-item-submitted-product-purchase-date-month,
    #webform-client-form-30 .form-item-submitted-product-purchase-date-year,
    #webform-client-form-30 .form-item-submitted-what-date-did-the-incident-occur-day,
    #webform-client-form-30 .form-item-submitted-what-date-did-the-incident-occur-month,
    #webform-client-form-30 .form-item-submitted-what-date-did-the-incident-occur-year,
    #webform-client-form-30 .form-date .form-group,
    #webform-client-form-16226 .form-item-submitted-title-product-purchase-date-day,
    #webform-client-form-16226 .form-item-submitted-title-product-purchase-date-month,
    #webform-client-form-16226 .form-item-submitted-title-product-purchase-date-year,
    #webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16226 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16226 .form-item-submitted-product-purchase-date-day,
    #webform-client-form-16226 .form-item-submitted-product-purchase-date-month,
    #webform-client-form-16226 .form-item-submitted-product-purchase-date-year,
    #webform-client-form-16226 .form-item-submitted-what-date-did-the-incident-occur-day,
    #webform-client-form-16226 .form-item-submitted-what-date-did-the-incident-occur-month,
    #webform-client-form-16226 .form-item-submitted-what-date-did-the-incident-occur-year,
    #webform-client-form-16226 .form-date .form-group,
    #webform-client-form-16225 .form-item-submitted-title-product-purchase-date-day,
    #webform-client-form-16225 .form-item-submitted-title-product-purchase-date-month,
    #webform-client-form-16225 .form-item-submitted-title-product-purchase-date-year,
    #webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16225 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16225 .form-item-submitted-product-purchase-date-day,
    #webform-client-form-16225 .form-item-submitted-product-purchase-date-month,
    #webform-client-form-16225 .form-item-submitted-product-purchase-date-year,
    #webform-client-form-16225 .form-item-submitted-what-date-did-the-incident-occur-day,
    #webform-client-form-16225 .form-item-submitted-what-date-did-the-incident-occur-month,
    #webform-client-form-16225 .form-item-submitted-what-date-did-the-incident-occur-year,
    #webform-client-form-16225 .form-date .form-group,
    #webform-client-form-16224 .form-item-submitted-title-product-purchase-date-day,
    #webform-client-form-16224 .form-item-submitted-title-product-purchase-date-month,
    #webform-client-form-16224 .form-item-submitted-title-product-purchase-date-year,
    #webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16224 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16224 .form-item-submitted-product-purchase-date-day,
    #webform-client-form-16224 .form-item-submitted-product-purchase-date-month,
    #webform-client-form-16224 .form-item-submitted-product-purchase-date-year,
    #webform-client-form-16224 .form-item-submitted-what-date-did-the-incident-occur-day,
    #webform-client-form-16224 .form-item-submitted-what-date-did-the-incident-occur-month,
    #webform-client-form-16224 .form-item-submitted-what-date-did-the-incident-occur-year,
    #webform-client-form-16224 .form-date .form-group,
    #webform-client-form-16223 .form-item-submitted-title-product-purchase-date-day,
    #webform-client-form-16223 .form-item-submitted-title-product-purchase-date-month,
    #webform-client-form-16223 .form-item-submitted-title-product-purchase-date-year,
    #webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-day,
    #webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-month,
    #webform-client-form-16223 .form-item-submitted-product-details-product-purchase-date-year,
    #webform-client-form-16223 .form-item-submitted-product-purchase-date-day,
    #webform-client-form-16223 .form-item-submitted-product-purchase-date-month,
    #webform-client-form-16223 .form-item-submitted-product-purchase-date-year,
    #webform-client-form-16223 .form-item-submitted-what-date-did-the-incident-occur-day,
    #webform-client-form-16223 .form-item-submitted-what-date-did-the-incident-occur-month,
    #webform-client-form-16223 .form-item-submitted-what-date-did-the-incident-occur-year,
    #webform-client-form-16223 .form-date .form-group {
      width: 100% !important; } }

.webform-cols .webform-component-markup,
#webform-client-form-30 .webform-component-markup,
#webform-client-form-16226 .webform-component-markup,
#webform-client-form-16225 .webform-component-markup,
#webform-client-form-16224 .webform-component-markup,
#webform-client-form-16223 .webform-component-markup {
  width: 100% !important;
  margin-bottom: 2rem; }

@media (max-width: 480px) {
  .webform-cols .panel-body,
  #webform-client-form-30 .panel-body,
  #webform-client-form-16226 .panel-body,
  #webform-client-form-16225 .panel-body,
  #webform-client-form-16224 .panel-body,
  #webform-client-form-16223 .panel-body {
    padding-left: 0;
    padding-right: 0; } }

.node-webform footer {
  display: none; }

/*------------------------------------*\
    Component: Language Switcher
\*------------------------------------*/
.desktop-language-switcher {
  display: flex; }
  .desktop-language-switcher .language-switcher-locale-session {
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 14px;
    padding: 2px;
    background-color: #fff; }
    .desktop-language-switcher .language-switcher-locale-session > li {
      order: 1; }
      .desktop-language-switcher .language-switcher-locale-session > li:has(a.session-active) {
        order: 0;
        display: block; }
        .desktop-language-switcher .language-switcher-locale-session > li:has(a.session-active) > a {
          margin-right: 4px; }
          .desktop-language-switcher .language-switcher-locale-session > li:has(a.session-active) > a::before {
            opacity: 1; }
          .desktop-language-switcher .language-switcher-locale-session > li:has(a.session-active) > a:after {
            display: block; }
      .desktop-language-switcher .language-switcher-locale-session > li.zh-hans > a::before {
        content: url("../../images/icons/icon-china.png"); }
      .desktop-language-switcher .language-switcher-locale-session > li.zh-hans > a::after {
        content: 'CN'; }
      .desktop-language-switcher .language-switcher-locale-session > li.en > a::before {
        content: url("../../images/icons/icon-nz.png"); }
      .desktop-language-switcher .language-switcher-locale-session > li.en > a::after {
        content: 'EN'; }
      .desktop-language-switcher .language-switcher-locale-session > li > a {
        color: inherit;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 0; }
        .desktop-language-switcher .language-switcher-locale-session > li > a::before {
          line-height: 1; }
        .desktop-language-switcher .language-switcher-locale-session > li > a:after {
          display: none;
          font-size: 14px;
          margin-left: 3px;
          margin-right: 4px;
          font-weight: 400; }

.mobile-language-switcher {
  display: flex; }
  .mobile-language-switcher .language-switcher-locale-session {
    display: flex;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 14px;
    padding: 4px; }
    .mobile-language-switcher .language-switcher-locale-session > li {
      order: 1; }
      .mobile-language-switcher .language-switcher-locale-session > li:has(a.session-active) {
        order: 0;
        display: block; }
        .mobile-language-switcher .language-switcher-locale-session > li:has(a.session-active) > a {
          margin-right: 4px; }
          .mobile-language-switcher .language-switcher-locale-session > li:has(a.session-active) > a::before {
            opacity: 1; }
          .mobile-language-switcher .language-switcher-locale-session > li:has(a.session-active) > a:after {
            display: block; }
      .mobile-language-switcher .language-switcher-locale-session > li.zh-hans > a::before {
        content: url("../../images/icons/icon-china.png"); }
      .mobile-language-switcher .language-switcher-locale-session > li.zh-hans > a::after {
        content: 'CN'; }
      .mobile-language-switcher .language-switcher-locale-session > li.en > a::before {
        content: url("../../images/icons/icon-nz.png"); }
      .mobile-language-switcher .language-switcher-locale-session > li.en > a::after {
        content: 'EN'; }
      .mobile-language-switcher .language-switcher-locale-session > li > a {
        color: inherit;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 0; }
        .mobile-language-switcher .language-switcher-locale-session > li > a::before {
          line-height: 1; }
        .mobile-language-switcher .language-switcher-locale-session > li > a:after {
          display: none;
          font-size: 14px;
          margin-left: 3px;
          margin-right: 4px;
          font-weight: 400; }

.language-switcher {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  color: #fff;
  width: 80px; }
  .language-switcher .language-switcher-locale-session {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    z-index: 50; }
    .language-switcher .language-switcher-locale-session > li {
      color: #fff;
      order: 1;
      display: none; }
      .language-switcher .language-switcher-locale-session > li:has(a.session-active) {
        order: 0;
        display: block; }
      .language-switcher .language-switcher-locale-session > li.zh-hans > a::before {
        content: url("../../images/icons/icon-china.png"); }
      .language-switcher .language-switcher-locale-session > li.zh-hans > a::after {
        content: 'CN'; }
      .language-switcher .language-switcher-locale-session > li.en > a::before {
        content: url("../../images/icons/icon-nz.png"); }
      .language-switcher .language-switcher-locale-session > li.en > a::after {
        content: 'EN'; }
      .language-switcher .language-switcher-locale-session > li > a {
        color: inherit;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 0; }
        .language-switcher .language-switcher-locale-session > li > a::before {
          line-height: 1; }
        .language-switcher .language-switcher-locale-session > li > a:after {
          font-size: 12px;
          margin-left: 8px;
          font-weight: bold; }
  .language-switcher:focus .language-switcher-locale-session, .language-switcher:active .language-switcher-locale-session, .language-switcher:hover .language-switcher-locale-session {
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 10px;
    width: 80px; }
    .language-switcher:focus .language-switcher-locale-session > li, .language-switcher:active .language-switcher-locale-session > li, .language-switcher:hover .language-switcher-locale-session > li {
      display: block;
      margin-bottom: 2px;
      color: #0a1a2a; }
      .language-switcher:focus .language-switcher-locale-session > li > a, .language-switcher:active .language-switcher-locale-session > li > a, .language-switcher:hover .language-switcher-locale-session > li > a {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 4px; }
        .language-switcher:focus .language-switcher-locale-session > li > a:after, .language-switcher:active .language-switcher-locale-session > li > a:after, .language-switcher:hover .language-switcher-locale-session > li > a:after {
          display: block; }

/*------------------------------------*\
    Theme: admin
\*------------------------------------*/
.path-user #contentblock {
  margin: 200px auto;
  width: 80%; }

.not-logged-in .tabs--primary {
  display: none; }

/*
======================================================
: Editing Tabs
======================================================
*/
.tabs--primary {
  @apply tw-flex;
  padding: 0.5rem 1rem;
  border: 1px dashed #eee;
  margin: 1rem 0; }
  .tabs--primary a {
    color: #333;
    margin-right: 1rem;
    font-size: 1.4rem; }
    .tabs--primary a:hover {
      text-decoration: underline; }

/*
======================================================
: User Login Page
======================================================
*/
.page-user .region-content form {
  margin: 0 auto; }
  .page-user .region-content form .help-block {
    font-size: 12px;
    display: none; }
  .page-user .region-content form .form-item {
    margin-bottom: 10px; }
    .page-user .region-content form .form-item label {
      width: 100px;
      display: inline-block; }
    .page-user .region-content form .form-item input {
      border: 1px solid #eee;
      height: 50px;
      width: 100%;
      text-indent: 20px;
      color: #555; }
  .page-user .region-content form button[type="submit"] {
    background: #333;
    color: #fff;
    border-radius: 3px;
    padding: 5px 20px;
    float: right; }

.page-user .region-content table {
  @apply tw-table-auto;
  background-color: #fff;
  position: relative;
  border: 1px solid #eee;
  position: relative;
  width: 100%;
  margin-top: 2rem;
  display: inline-table; }
  .page-user .region-content table thead tr {
    border-bottom: 1px solid #eee; }
  .page-user .region-content table tr th,
  .page-user .region-content table tr td {
    font-size: 1.4rem;
    padding: 1rem 1rem;
    border-bottom: none; }
    @media screen and (min-width: 1025px) {
      .page-user .region-content table tr th.views-field-order-id,
      .page-user .region-content table tr td.views-field-order-id {
        display: flex; } }

.table-responsive {
  position: relative; }
  @media screen and (max-width: 1025px) {
    .table-responsive {
      width: 100%;
      overflow-x: scroll;
      border: 1px solid #eee; } }

/** Page user elements **/
@media screen and (max-width: 1025px) {
  .nav-tabs {
    flex-direction: column; } }

/*
======================================================
: masquerade
======================================================
*/
section#block-masquerade-masquerade {
  position: fixed;
  width: 100vw;
  bottom: 0;
  height: 4rem;
  background: #333;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  z-index: 50; }
  section#block-masquerade-masquerade h2 {
    margin-bottom: 0;
    margin-right: 1rem; }
  section#block-masquerade-masquerade .form-item {
    position: relative; }
  section#block-masquerade-masquerade .description {
    position: relative;
    display: flex; }
    section#block-masquerade-masquerade .description .placeholder {
      color: #4d87ca; }
    section#block-masquerade-masquerade .description ul,
    section#block-masquerade-masquerade .description ol,
    section#block-masquerade-masquerade .description dl,
    section#block-masquerade-masquerade .description ul li {
      margin-bottom: 0; }
    section#block-masquerade-masquerade .description #quick_switch_links {
      margin-left: 10px;
      display: flex; }
      section#block-masquerade-masquerade .description #quick_switch_links a {
        margin-left: 0.5rem; }
  section#block-masquerade-masquerade form .dropdown {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 99;
    left: 0;
    bottom: 100% !important; }
    section#block-masquerade-masquerade form .dropdown .dropdown-menu {
      width: 100%;
      height: auto;
      max-height: 200px;
      overflow-y: scroll;
      padding: 8px;
      background-color: #fff !important;
      border-radius: 0;
      bottom: 0;
      z-index: 99;
      float: left;
      position: absolute; }
      section#block-masquerade-masquerade form .dropdown .dropdown-menu > li {
        position: relative;
        width: 100%;
        color: #000; }
    section#block-masquerade-masquerade form .dropdown a {
      position: relative;
      float: left;
      width: 100%;
      display: block;
      padding: 0 8px;
      color: #000;
      font-size: 12px !important; }
  section#block-masquerade-masquerade form div {
    position: relative;
    align-items: center; }
    section#block-masquerade-masquerade form div:not(.inline-flex) {
      display: flex; }
  section#block-masquerade-masquerade form div.container-inilne {
    position: relative;
    justify-content: center;
    align-items: center; }
  section#block-masquerade-masquerade form input {
    border-radius: 3px;
    background-color: #000;
    color: #c1c1c1;
    padding: 0.5rem;
    text-indent: 0.5rem; }
  section#block-masquerade-masquerade form button[type="submit"] {
    display: inline-block;
    margin: 0 2rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem; }
    section#block-masquerade-masquerade form button[type="submit"] {
      background-color: #797979;
      border-radius: 5px;
      display: inline-block;
      color: #fff;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      transition: all 0.5s;
      font-size: 1.4rem;
      text-align: center; }
    section#block-masquerade-masquerade form button[type="submit"]:hover, section#block-masquerade-masquerade form button[type="submit"]:active {
      background-color: #939393; }

/*------------------------------------*\
    Global Page Styles
\*------------------------------------*/
/*
======================================================
: Global hidden elements
======================================================
*/
#sidebar-trigger {
  display: none; }

.page_header {
  display: none; }

/*
======================================================
: Userbar, hide and show different element acrroding
  to user login status
======================================================
*/
a.user-logout {
  display: flex !important; }

a.user-login {
  display: none !important; }

.logged-in a.user-logout {
  display: none !important; }

.logged-in a.user-login {
  display: flex !important; }

/*
======================================================
: Inifinite scroll loader
======================================================
*/
.views_infinite_scroll-ajax-loader {
  margin: 4rem 0;
  display: flex;
  justify-content: center; }

/*------------------------------------*\
    Page: Home
\*------------------------------------*/
.front {
  overflow-x: hidden;
  /*
  ======================================================
  : Page Content
  ======================================================
  */
  /** Region Content **/ }
  .front .breadcrumb {
    display: none; }
  .front .block-system {
    margin: 8rem 0; }
    .front .block-system .content-left {
      padding-right: 5rem; }
      .front .block-system .content-left p {
        margin-bottom: 3rem; }
        .front .block-system .content-left p strong {
          font-weight: bold; }
    @media screen and (max-width: 1025px) {
      .front .block-system {
        margin: 3rem 0; }
        .front .block-system .tw-flex {
          @apply tw-flex-wrap; }
        .front .block-system .content-left {
          padding-right: 0;
          width: 100% !important; }
        .front .block-system .content-right {
          order: -1;
          margin-bottom: 2rem;
          width: 100% !important; } }
  .front .region-content > section.block {
    position: relative; }
  .front .region-content section:not(.block-system) .view-header a {
    position: absolute;
    right: 0;
    top: 0;
    color: #4d87ca;
    transition: color 0.3s; }
    .front .region-content section:not(.block-system) .view-header a:hover {
      color: #9cbce1; }
  .front .region-content .block-title {
    font-weight: 500;
    font-size: 1.8rem;
    display: inline;
    padding: 1rem 0;
    text-transform: uppercase;
    border-bottom: 6px solid #4d87ca; }
    @media screen and (max-width: 641px) {
      .front .region-content .block-title {
        font-size: 1.5rem; } }

/*
======================================================
: Brands
======================================================
*/
.block-brands {
  margin: 4rem 0;
  position: relative; }
  .block-brands:after {
    content: "";
    display: block;
    height: 80%;
    position: absolute;
    width: 100vw;
    left: calc(0px - (100vw - 100%) / 2);
    top: 10%;
    background: #f3f3f3;
    z-index: -1;
    visibility: visible; }
  .block-brands .view-content {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
    .block-brands .view-content img {
      width: 100%; }
  @media screen and (max-width: 1025px) {
    .block-brands .view-content {
      display: grid;
      grid-gap: 0rem;
      grid-template-columns: repeat(1, minmax(0, 1fr)); }
      .block-brands .view-content .views-row {
        margin-bottom: 3rem; }
      .block-brands .view-content .views-row {
        margin-bottom: 3rem; }
    .block-brands:after {
      top: 4%; } }

/*
======================================================
: Popular Prodcuts - quick tabs
======================================================
*/
.block-quicktabs {
  margin: 5rem 0 3rem; }
  .block-quicktabs .contextual-links-region {
    position: static; }
  .block-quicktabs ul.quicktabs-tabs {
    padding: 0;
    background-color: #fff;
    border: none; }
    .block-quicktabs ul.quicktabs-tabs li {
      margin-right: 2rem;
      padding: 1rem 0;
      border: none;
      background: none;
      transition: all 0.3s; }
      .block-quicktabs ul.quicktabs-tabs li:hover {
        background-color: #fff;
        border-bottom: 6px solid #346eb0; }
      .block-quicktabs ul.quicktabs-tabs li.active, .block-quicktabs ul.quicktabs-tabs li.selected {
        border-bottom: 6px solid #4d87ca;
        padding: 1rem 0; }
        .block-quicktabs ul.quicktabs-tabs li.active a, .block-quicktabs ul.quicktabs-tabs li.selected a {
          color: #000; }
      .block-quicktabs ul.quicktabs-tabs li a {
        font-weight: 500;
        font-size: 1.8rem;
        color: #9c9c9c;
        transition: all 0.3s;
        padding: 0 !important; }
        .block-quicktabs ul.quicktabs-tabs li a:hover {
          background-color: #fff; }
        @media screen and (max-width: 641px) {
          .block-quicktabs ul.quicktabs-tabs li a {
            font-size: 1.5rem; } }
    @media screen and (max-width: 769px) {
      .block-quicktabs ul.quicktabs-tabs {
        display: flex;
        justify-content: space-between; }
        .block-quicktabs ul.quicktabs-tabs li {
          margin-right: 0; } }
  .block-quicktabs .view-footer {
    display: none;
    text-align: center;
    margin: 5rem 0 2rem; }
    .block-quicktabs .view-footer a {
      color: #4d87ca;
      transition: color 0.3s; }
      .block-quicktabs .view-footer a:hover {
        color: #9cbce1; }
  .block-quicktabs .quicktabs_main .view-content {
    border-top: 1px solid #e9e9e9;
    margin-top: 15px;
    padding-top: 3rem;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
    @media screen and (max-width: 1025px) {
      .block-quicktabs .quicktabs_main .view-content {
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    .block-quicktabs .quicktabs_main .view-content > div .node-product {
      padding: 1.4rem;
      border: 0.8px solid #f1f1f1;
      height: 100%;
      @apply tw-flex tw-flex-col tw-justify-between;
      transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product:hover {
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12); }
        .block-quicktabs .quicktabs_main .view-content > div .node-product:hover h2 a {
          color: #E7760E; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product:after {
        display: none; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product img {
        width: 100%;
        height: 200px;
        object-fit: contain; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product h2 {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6;
        margin: 1rem 0; }
        .block-quicktabs .quicktabs_main .view-content > div .node-product h2 a {
          transition: all 300ms ease;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease; }
          .block-quicktabs .quicktabs_main .view-content > div .node-product h2 a:hover {
            color: #E7760E; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product .crossout-price {
        font-size: 1.8rem;
        text-decoration: line-through;
        color: #d43333; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product .group-price-section {
        @apply tw-flex tw-flex-col; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product .display-price {
        font-weight: 300;
        font-size: 1.5rem;
        color: #898989;
        text-align: left; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions {
        position: relative;
        margin-top: 1rem; }
        .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions button {
          width: 100%; }
          .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions button {
            background-color: #4d87ca;
            border-radius: 5px;
            display: inline-block;
            color: #fff;
            text-transform: uppercase;
            padding: 0.8rem 2rem;
            transition: all 0.5s;
            font-size: 1.4rem;
            text-align: center; }
          .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions button:hover, .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions button:active {
            background-color: #74a1d6; }
        .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions .alert {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          background: #E7760E;
          color: #fff;
          padding-right: 2.5rem;
          margin-bottom: 4rem;
          display: inline-flex;
          flex-wrap: wrap;
          text-align: left;
          font-size: 1.2rem; }
          .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions .alert strong {
            display: none; }
          .block-quicktabs .quicktabs_main .view-content > div .node-product .form-actions .alert > .close {
            color: #fff; }
      .block-quicktabs .quicktabs_main .view-content > div .node-product p,
      .block-quicktabs .quicktabs_main .view-content > div .node-product .form-item-qty,
      .block-quicktabs .quicktabs_main .view-content > div .node-product .form-item-qty ~ fieldset,
      .block-quicktabs .quicktabs_main .view-content > div .node-product .add-to-cart fieldset.panel,
      .block-quicktabs .quicktabs_main .view-content > div .node-product .field-name-koda-price-policy-sku {
        display: none; }
    @media screen and (max-width: 641px) {
      .block-quicktabs .quicktabs_main .view-content {
        margin-top: 0;
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: repeat(1, minmax(0, 1fr)); }
        .block-quicktabs .quicktabs_main .view-content .views-row {
          margin-bottom: 3rem; }
        .block-quicktabs .quicktabs_main .view-content .views-row {
          margin-bottom: 3rem; } }
  @media screen and (max-width: 641px) {
    .block-quicktabs .view-header {
      display: none; }
    .block-quicktabs .view-footer {
      display: block; } }

/*
======================================================
: News
======================================================
*/
.block-news {
  margin: 5rem 0 10rem; }
  .block-news .view-content {
    padding-top: 3rem;
    border-top: 1px solid #e9e9e9;
    margin-top: 15px;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
    .block-news .view-content .views-row .node-news .field-name-title {
      margin: 1rem 0; }
      .block-news .view-content .views-row .node-news .field-name-title h2 {
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.5; }
        .block-news .view-content .views-row .node-news .field-name-title h2 a:hover {
          color: #4d87ca; }
    .block-news .view-content .views-row .node-news .field-name-field-summary {
      color: #555;
      font-size: 1.6rem;
      font-weight: 300;
      height: 7.5rem;
      overflow-y: hidden; }
    .block-news .view-content .views-row .node-news .field-name-node-link a {
      color: #4d87ca;
      transition: color 0.3s; }
      .block-news .view-content .views-row .node-news .field-name-node-link a:hover {
        color: #9cbce1; }
      .block-news .view-content .views-row .node-news .field-name-node-link a:after {
        content: ">";
        margin-top: 2px;
        margin-left: 1rem; }
    .block-news .view-content .views-row .node-news img {
      width: 100%;
      height: 25rem;
      object-fit: cover; }
  @media screen and (max-width: 769px) {
    .block-news {
      margin: 5rem 0 0; }
      .block-news .view-content {
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: repeat(1, minmax(0, 1fr)); }
        .block-news .view-content .views-row {
          margin-bottom: 3rem; }
        .block-news .view-content .views-row {
          margin-bottom: 3rem; } }
  .block-news .views-row {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
    padding: 2rem; }
    .block-news .views-row .field-name-node-link {
      margin-top: 2rem; }
      .block-news .views-row .field-name-node-link a {
        width: 100%;
        text-align: center;
        color: #fff !important; }
        .block-news .views-row .field-name-node-link a {
          background-color: #4d87ca;
          border-radius: 5px;
          display: inline-block;
          color: #fff;
          text-transform: uppercase;
          padding: 0.8rem 2rem;
          transition: all 0.5s;
          font-size: 1.4rem;
          text-align: center; }
        .block-news .views-row .field-name-node-link a:hover, .block-news .views-row .field-name-node-link a:active {
          background-color: #74a1d6; }

/*------------------------------------*\
    Page: Category
\*------------------------------------*/
.page-taxonomy .sidebar .view-footer-category-links {
  display: block;
  border-left: 1px solid #D7D7D7; }
  .page-taxonomy .sidebar .view-footer-category-links .views-row {
    font-weight: 300;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border-left: 4px solid #fff;
    transition: all 0.5s; }
    .page-taxonomy .sidebar .view-footer-category-links .views-row.active, .page-taxonomy .sidebar .view-footer-category-links .views-row:hover {
      background-color: #f8f8f8;
      border-left: 4px solid #4D87CA; }

.page-taxonomy .region-content .block-system > p {
  display: none; }

.page-taxonomy .region-content h2 {
  font-size: 3rem;
  font-weight: 300;
  border-bottom: 1px solid #D9D9D9;
  padding: 0rem 0 2rem;
  margin-bottom: 3rem; }

.page-taxonomy .region-content p {
  font-size: 1.6rem;
  line-height: 1.6; }

.page-taxonomy .region-content img {
  margin: 2rem 0; }

.page-taxonomy .region-content .field-name-category-to-products {
  width: 100%;
  text-align: center;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 2rem 0;
  margin-top: 5rem; }
  .page-taxonomy .region-content .field-name-category-to-products a {
    color: #4d87ca;
    transition: color 0.3s; }
    .page-taxonomy .region-content .field-name-category-to-products a:hover {
      color: #9cbce1; }

/*------------------------------------*\
    Page: Product List
\*------------------------------------*/
.page-products .content#content-block .view[class*='view-product-and-product-collection'] .view-content {
  padding: 2rem;
  background-color: #fafafa;
  border: 1px solid #f1f1f1; }
  .page-products .content#content-block .view[class*='view-product-and-product-collection'] .view-content .node-teaser {
    background-color: #fff;
    border: 1px solid #e2e2e2; }

.page-products .page_header {
  display: block; }
  .page-products .page_header .contextual-links-wrapper {
    top: -15px; }

.page-products .sidebar .region-sidebar-first {
  border: none; }

.page-products .sidebar .search-filter {
  font-size: 1.3rem;
  font-weight: 300;
  background: #fafafa;
  margin-bottom: 2rem;
  padding: 2rem;
  border: 1px solid #f1f1f1; }
  .page-products .sidebar .search-filter .block-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f1f1f1; }
  .page-products .sidebar .search-filter li {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5em; }
    .page-products .sidebar .search-filter li ul {
      width: 100%;
      margin-left: 13px;
      margin-top: 10px; }
    .page-products .sidebar .search-filter li a {
      width: 200px;
      position: absolute; }

.page-products .region-content .view-content {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .page-products .region-content .view-content > div .node-product {
    padding: 1.4rem;
    border: 0.8px solid #f1f1f1;
    height: 100%;
    @apply tw-flex tw-flex-col tw-justify-between;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease; }
    .page-products .region-content .view-content > div .node-product:hover {
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12); }
      .page-products .region-content .view-content > div .node-product:hover h2 a {
        color: #E7760E; }
    .page-products .region-content .view-content > div .node-product:after {
      display: none; }
    .page-products .region-content .view-content > div .node-product img {
      width: 100%;
      height: 200px;
      object-fit: contain; }
    .page-products .region-content .view-content > div .node-product h2 {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.6;
      margin: 1rem 0; }
      .page-products .region-content .view-content > div .node-product h2 a {
        transition: all 300ms ease;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease; }
        .page-products .region-content .view-content > div .node-product h2 a:hover {
          color: #E7760E; }
    .page-products .region-content .view-content > div .node-product .crossout-price {
      font-size: 1.8rem;
      text-decoration: line-through;
      color: #d43333; }
    .page-products .region-content .view-content > div .node-product .group-price-section {
      @apply tw-flex tw-flex-col; }
    .page-products .region-content .view-content > div .node-product .display-price {
      font-weight: 300;
      font-size: 1.5rem;
      color: #898989;
      text-align: left; }
    .page-products .region-content .view-content > div .node-product .form-actions {
      position: relative;
      margin-top: 1rem; }
      .page-products .region-content .view-content > div .node-product .form-actions button {
        width: 100%; }
        .page-products .region-content .view-content > div .node-product .form-actions button {
          background-color: #4d87ca;
          border-radius: 5px;
          display: inline-block;
          color: #fff;
          text-transform: uppercase;
          padding: 0.8rem 2rem;
          transition: all 0.5s;
          font-size: 1.4rem;
          text-align: center; }
        .page-products .region-content .view-content > div .node-product .form-actions button:hover, .page-products .region-content .view-content > div .node-product .form-actions button:active {
          background-color: #74a1d6; }
      .page-products .region-content .view-content > div .node-product .form-actions .alert {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background: #E7760E;
        color: #fff;
        padding-right: 2.5rem;
        margin-bottom: 4rem;
        display: inline-flex;
        flex-wrap: wrap;
        text-align: left;
        font-size: 1.2rem; }
        .page-products .region-content .view-content > div .node-product .form-actions .alert strong {
          display: none; }
        .page-products .region-content .view-content > div .node-product .form-actions .alert > .close {
          color: #fff; }
    .page-products .region-content .view-content > div .node-product p,
    .page-products .region-content .view-content > div .node-product .form-item-qty,
    .page-products .region-content .view-content > div .node-product .form-item-qty ~ fieldset,
    .page-products .region-content .view-content > div .node-product .add-to-cart fieldset.panel,
    .page-products .region-content .view-content > div .node-product .field-name-koda-price-policy-sku {
      display: none; }
  @media screen and (max-width: 1281px) {
    .page-products .region-content .view-content {
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: repeat(3, minmax(0, 1fr)); } }
  @media screen and (max-width: 1025px) {
    .page-products .region-content .view-content {
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  @media screen and (max-width: 769px) {
    .page-products .region-content .view-content {
      display: grid;
      grid-gap: 0;
      grid-template-columns: repeat(1, minmax(0, 1fr)); }
      .page-products .region-content .view-content .views-row {
        margin-bottom: 3rem; } }
  .page-products .region-content .view-content .product-info .uc-price {
    font-weight: 500;
    font-size: 1.8rem;
    color: #203c5d; }
  .page-products .region-content .view-content .product-info .price-suffixes {
    font-size: 12px; }
  .page-products .region-content .view-content .product-info .crossout-price {
    display: block; }

.page-products .region-content .add-to-cart {
  border-top: 1px solid #f1f1f1;
  margin-top: 1rem;
  padding-top: 1rem; }
  .page-products .region-content .add-to-cart > form > div {
    display: flex; }
  .page-products .region-content .add-to-cart .form-item-qty {
    display: flex !important;
    justify-content: center;
    align-items: center; }
    .page-products .region-content .add-to-cart .form-item-qty .form-control {
      border-radius: 4px;
      border: 1px solid #828282;
      text-align: center;
      max-width: 5rem;
      margin-right: 0.5rem;
      padding-left: 2px;
      padding-right: 2px; }
    .page-products .region-content .add-to-cart .form-item-qty label {
      font-size: 0;
      padding-right: 1rem; }
      .page-products .region-content .add-to-cart .form-item-qty label::before {
        content: 'Qty';
        font-size: 1.6rem; }
      .page-products .region-content .add-to-cart .form-item-qty label span {
        display: none; }
  .page-products .region-content .add-to-cart .form-actions {
    margin-top: 0 !important;
    display: flex;
    flex: 1; }
    .page-products .region-content .add-to-cart .form-actions > button {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      font-size: 12px !important; }

.page-products .region-content .pager.pager--infinite-scroll {
  margin-top: 3rem;
  padding: 0;
  list-style: none; }
  .page-products .region-content .pager.pager--infinite-scroll .pager__item {
    list-style: none;
    text-align: center; }
    .page-products .region-content .pager.pager--infinite-scroll .pager__item > a {
      transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      font-weight: 500;
      font-size: 1.8rem;
      color: #fff;
      padding: 1rem 2rem;
      background-color: #E7760E;
      border: 1px solid #E7760E;
      border-radius: 5px; }
      .page-products .region-content .pager.pager--infinite-scroll .pager__item > a:hover {
        background-color: #86bc86;
        border-color: #86bc86; }

@media screen and (max-width: 1025px) {
  .page-products .sidebar {
    position: fixed;
    width: 80vw !important;
    background: #fff;
    height: calc(100vh - 7rem);
    padding: 2rem;
    left: 0;
    top: auto;
    transition: all 0.5s;
    transform: translateX(-80vw);
    top: 7rem;
    z-index: 99; }
    .page-products .sidebar .sidebar-trigger::after {
      content: 'filter';
      position: absolute;
      right: -2rem;
      height: 8rem;
      width: 2rem;
      background: #4d87ca;
      color: #fff;
      writing-mode: vertical-lr;
      text-align: center;
      line-height: 2rem;
      top: 0;
      text-transform: uppercase;
      font-size: 1.4rem;
      transform: translateY(10px);
      top: 50%; }
      .page-products .sidebar .sidebar-trigger::after:hover {
        background: #6194d0; }
    .page-products .sidebar.opened {
      box-shadow: 1px 1px 10px #aaa0a0;
      transform: translateX(0); }
      .page-products .sidebar.opened .sidebar-trigger::after {
        content: 'close'; }
  .page-products .main-content {
    padding-left: 0 !important; } }

/*
======================================================
  Product Taxonomy Term Page
======================================================
*/
.products.page-taxonomy-term .block-system {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .products.page-taxonomy-term .block-system .term-listing-heading {
    grid-column: 1 / 6; }
  @media screen and (max-width: 1025px) {
    .products.page-taxonomy-term .block-system {
      grid-template-columns: repeat(3, minmax(0, 1fr)); }
      .products.page-taxonomy-term .block-system .term-listing-heading {
        grid-column: 1 / 4; } }
  @media screen and (max-width: 641px) {
    .products.page-taxonomy-term .block-system {
      grid-template-columns: auto !important; }
      .products.page-taxonomy-term .block-system .term-listing-heading {
        grid-column: auto !important; } }
  .products.page-taxonomy-term .block-system .term-listing-heading .field-name-category-to-products a {
    font-weight: bold; }
    .products.page-taxonomy-term .block-system .term-listing-heading .field-name-category-to-products a:hover {
      color: #0a1a2a;
      text-decoration: underline; }
  .products.page-taxonomy-term .block-system .node-product.node-teaser {
    padding: 1.4rem;
    border: 0.8px solid #f1f1f1;
    height: 100%;
    @apply tw-flex tw-flex-col tw-justify-between;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser:hover {
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12); }
      .products.page-taxonomy-term .block-system .node-product.node-teaser:hover h2 a {
        color: #E7760E; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser:after {
      display: none; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser img {
      width: 100%;
      height: 200px;
      object-fit: contain; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser h2 {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.6;
      margin: 1rem 0; }
      .products.page-taxonomy-term .block-system .node-product.node-teaser h2 a {
        transition: all 300ms ease;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease; }
        .products.page-taxonomy-term .block-system .node-product.node-teaser h2 a:hover {
          color: #E7760E; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser .crossout-price {
      font-size: 1.8rem;
      text-decoration: line-through;
      color: #d43333; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser .group-price-section {
      @apply tw-flex tw-flex-col; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser .display-price {
      font-weight: 300;
      font-size: 1.5rem;
      color: #898989;
      text-align: left; }
      .products.page-taxonomy-term .block-system .node-product.node-teaser .display-price .uc-price {
        font-weight: bold;
        font-size: 1.8rem;
        color: #203c5d; }
      .products.page-taxonomy-term .block-system .node-product.node-teaser .display-price .price-suffixes {
        font-size: 12px; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions {
      position: relative;
      margin-top: 1rem; }
      .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions button {
        width: 100%; }
        .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions button {
          background-color: #4d87ca;
          border-radius: 5px;
          display: inline-block;
          color: #fff;
          text-transform: uppercase;
          padding: 0.8rem 2rem;
          transition: all 0.5s;
          font-size: 1.4rem;
          text-align: center; }
        .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions button:hover, .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions button:active {
          background-color: #74a1d6; }
      .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions .alert {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background: #E7760E;
        color: #fff;
        padding-right: 2.5rem;
        margin-bottom: 4rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.2rem; }
        .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions .alert strong {
          display: none; }
        .products.page-taxonomy-term .block-system .node-product.node-teaser .form-actions .alert > .close {
          color: #fff; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser .add-to-cart form p {
      display: none; }
    .products.page-taxonomy-term .block-system .node-product.node-teaser .add-to-cart form .form-item-qty {
      position: relative;
      display: flex;
      justify-content: space-between; }
      .products.page-taxonomy-term .block-system .node-product.node-teaser .add-to-cart form .form-item-qty .form-control {
        text-align: center;
        border-radius: 4px;
        border: 1px solid #ddd;
        text-align: center; }

/*
======================================================
  Products
======================================================
*/
.products__favourites {
  margin-bottom: 2rem;
  padding: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #e2e2e2;
  background: #efefef; }
  .products__favourites .block-title {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.8rem; }
  .products__favourites .node.node-teaser {
    background-color: #fff; }

/*------------------------------------*\
    Page: Product
\*------------------------------------*/
.node-type-product .content .sidebar {
  display: none; }

.node-type-product .content .main-content {
  width: 100% !important;
  padding-left: 0; }

.node-type-product .product-wrapper {
  justify-content: space-between; }

.node-type-product .product-left {
  width: 40%;
  position: relative; }
  .node-type-product .product-left .product-image {
    width: 100%; }
  .node-type-product .product-left img {
    width: 100%;
    height: auto;
    border: 0.8px solid #e0e0e0;
    padding: 2rem; }
  .node-type-product .product-left .slick {
    position: relative;
    width: 100%;
    height: 100%; }
    .node-type-product .product-left .slick__arrow {
      position: absolute;
      width: 100%;
      padding: 0 2rem;
      top: 0;
      margin-top: 40%;
      display: flex;
      justify-content: space-between; }
      .node-type-product .product-left .slick__arrow button.slick-arrow {
        position: relative;
        outline: none;
        font-size: 0;
        padding: 0.5rem 1rem;
        background-color: rgba(255, 255, 255, 0.8);
        opacity: 0.5; }
        .node-type-product .product-left .slick__arrow button.slick-arrow:hover {
          opacity: 1; }
        .node-type-product .product-left .slick__arrow button.slick-arrow.slick-prev::before, .node-type-product .product-left .slick__arrow button.slick-arrow.slick-next::before {
          font-family: "Font Awesome 5 Pro";
          font-size: 2.5rem;
          line-height: 1;
          font-weight: normal; }
        .node-type-product .product-left .slick__arrow button.slick-arrow.slick-prev::before {
          content: "\f104"; }
        .node-type-product .product-left .slick__arrow button.slick-arrow.slick-next::before {
          content: "\f105"; }

.node-type-product .product-right {
  width: 52%; }
  .node-type-product .product-right .field-name-title h1 {
    font-size: 3rem;
    font-weight: normal;
    line-height: 2; }
  .node-type-product .product-right .group-price-info-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    margin: 1.5rem 0; }
    .node-type-product .product-right .group-price-info-wrapper .product-info {
      font-size: 1.6rem;
      font-weight: 300;
      color: #8f8f8f; }
    .node-type-product .product-right .group-price-info-wrapper .display-price {
      text-align: left;
      padding: 0;
      margin: 0; }
      .node-type-product .product-right .group-price-info-wrapper .display-price .uc-price {
        font-size: 3.8rem;
        color: #d43333;
        font-weight: 500;
        line-height: 56px; }
      .node-type-product .product-right .group-price-info-wrapper .display-price .crossout-price {
        font-size: 1.8rem;
        display: inline;
        text-decoration: line-through;
        color: #d43333; }
  .node-type-product .product-right .group-description-section h3 {
    margin-bottom: 2rem; }
  .node-type-product .product-right .group-description-section .field-name-body {
    font-size: 1.6rem;
    font-weight: 300;
    color: #5e5e5e;
    line-height: 1.7;
    letter-spacing: 0.02em; }
  .node-type-product .product-right .add-to-cart {
    margin-top: 3rem; }
    .node-type-product .product-right .add-to-cart form > div {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      @media screen and (max-width: 641px) {
        .node-type-product .product-right .add-to-cart form > div {
          justify-content: center; } }
      .node-type-product .product-right .add-to-cart form > div p,
      .node-type-product .product-right .add-to-cart form > div fieldset.panel {
        display: none; }
      .node-type-product .product-right .add-to-cart form > div .form-item {
        height: 100%; }
        .node-type-product .product-right .add-to-cart form > div .form-item input {
          border: 1px solid #828282;
          border-radius: 3px;
          text-align: center;
          color: #333;
          height: 37px;
          max-width: 5rem; }
      .node-type-product .product-right .add-to-cart form > div .form-item-qty {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .node-type-product .product-right .add-to-cart form > div .form-item-qty label {
          width: auto;
          padding-right: 1rem;
          display: flex;
          font-size: 0; }
          .node-type-product .product-right .add-to-cart form > div .form-item-qty label::before {
            content: "Qty";
            font-size: 1.6rem; }
          .node-type-product .product-right .add-to-cart form > div .form-item-qty label span {
            display: none; }
      .node-type-product .product-right .add-to-cart form > div .form-actions {
        position: relative;
        margin-left: 1rem; }
        .node-type-product .product-right .add-to-cart form > div .form-actions button {
          width: 18rem;
          border-color: #E7760E; }
          .node-type-product .product-right .add-to-cart form > div .form-actions button {
            background-color: #E7760E;
            border-radius: 5px;
            display: inline-block;
            color: #fff;
            text-transform: uppercase;
            padding: 0.8rem 2rem;
            transition: all 0.5s;
            font-size: 1.4rem;
            text-align: center; }
          .node-type-product .product-right .add-to-cart form > div .form-actions button:hover, .node-type-product .product-right .add-to-cart form > div .form-actions button:active {
            background-color: #f39035; }
          @media screen and (max-width: 641px) {
            .node-type-product .product-right .add-to-cart form > div .form-actions button {
              width: auto; } }
        .node-type-product .product-right .add-to-cart form > div .form-actions .alert {
          position: absolute;
          min-width: 37rem;
          top: 100%;
          right: 0;
          margin-top: 1rem;
          background: #eee;
          padding: 2rem 1rem;
          border-radius: 3px;
          line-height: 100%;
          color: #555;
          font-size: 1.4rem;
          border: 1px solid #ddd;
          display: inline-flex !important;
          text-align: left; }
          .node-type-product .product-right .add-to-cart form > div .form-actions .alert strong {
            display: none; }
          @media screen and (max-width: 641px) {
            .node-type-product .product-right .add-to-cart form > div .form-actions .alert {
              width: auto;
              min-width: 240%; } }

.node-type-product .product-tabs {
  margin-top: 10rem; }
  .node-type-product .product-tabs .horizontal-tabs {
    border: none; }
  .node-type-product .product-tabs .horizontal-tabs-list {
    padding: 0;
    background-color: #fff;
    border: none; }
    .node-type-product .product-tabs .horizontal-tabs-list li {
      margin-right: 2rem;
      padding: 1rem 0;
      border: none;
      background: none;
      transition: all 0.3s; }
      .node-type-product .product-tabs .horizontal-tabs-list li:hover {
        background-color: #fff;
        border-bottom: 6px solid #346eb0; }
      .node-type-product .product-tabs .horizontal-tabs-list li.active, .node-type-product .product-tabs .horizontal-tabs-list li.selected {
        border-bottom: 6px solid #4d87ca;
        padding: 1rem 0; }
        .node-type-product .product-tabs .horizontal-tabs-list li.active a, .node-type-product .product-tabs .horizontal-tabs-list li.selected a {
          color: #000; }
      .node-type-product .product-tabs .horizontal-tabs-list li a {
        font-weight: 500;
        font-size: 1.8rem;
        color: #9c9c9c;
        transition: all 0.3s;
        padding: 0 !important; }
        .node-type-product .product-tabs .horizontal-tabs-list li a:hover {
          background-color: #fff; }
        @media screen and (max-width: 641px) {
          .node-type-product .product-tabs .horizontal-tabs-list li a {
            font-size: 1.5rem; } }
    @media screen and (max-width: 769px) {
      .node-type-product .product-tabs .horizontal-tabs-list {
        display: flex;
        justify-content: space-between; }
        .node-type-product .product-tabs .horizontal-tabs-list li {
          margin-right: 0; } }
  .node-type-product .product-tabs .horizontal-tabs-pane {
    padding: 0;
    border-top: 1px solid #e9e9e9;
    padding-top: 4rem;
    margin-top: -7px; }

.node-type-product .field-name-field-direction-of-use {
  font-size: 1.6rem;
  font-weight: 300;
  color: #5e5e5e;
  line-height: 1.7; }

.node-type-product .field-name-field-related-products > .field-items {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .node-type-product .field-name-field-related-products > .field-items > div .node-product {
    padding: 1.4rem;
    border: 0.8px solid #f1f1f1;
    height: 100%;
    @apply tw-flex tw-flex-col tw-justify-between;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product:hover {
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12); }
      .node-type-product .field-name-field-related-products > .field-items > div .node-product:hover h2 a {
        color: #E7760E; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product:after {
      display: none; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product img {
      width: 100%;
      height: 200px;
      object-fit: contain; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product h2 {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.6;
      margin: 1rem 0; }
      .node-type-product .field-name-field-related-products > .field-items > div .node-product h2 a {
        transition: all 300ms ease;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease; }
        .node-type-product .field-name-field-related-products > .field-items > div .node-product h2 a:hover {
          color: #E7760E; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .crossout-price {
      font-size: 1.8rem;
      text-decoration: line-through;
      color: #d43333; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .group-price-section {
      @apply tw-flex tw-flex-col; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .display-price {
      font-weight: 300;
      font-size: 1.5rem;
      color: #898989;
      text-align: left; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions {
      position: relative;
      margin-top: 1rem; }
      .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions button {
        width: 100%; }
        .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions button {
          background-color: #4d87ca;
          border-radius: 5px;
          display: inline-block;
          color: #fff;
          text-transform: uppercase;
          padding: 0.8rem 2rem;
          transition: all 0.5s;
          font-size: 1.4rem;
          text-align: center; }
        .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions button:hover, .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions button:active {
          background-color: #74a1d6; }
      .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions .alert {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background: #E7760E;
        color: #fff;
        padding-right: 2.5rem;
        margin-bottom: 4rem;
        display: inline-flex;
        flex-wrap: wrap;
        text-align: left;
        font-size: 1.2rem; }
        .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions .alert strong {
          display: none; }
        .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-actions .alert > .close {
          color: #fff; }
    .node-type-product .field-name-field-related-products > .field-items > div .node-product p,
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-item-qty,
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .form-item-qty ~ fieldset,
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .add-to-cart fieldset.panel,
    .node-type-product .field-name-field-related-products > .field-items > div .node-product .field-name-koda-price-policy-sku {
      display: none; }

@media screen and (max-width: 1025px) {
  .node-type-product .product-wrapper {
    flex-wrap: wrap; }
  .node-type-product .product-left {
    width: 100%;
    margin-bottom: 3rem; }
  .node-type-product .product-right {
    width: 100%; }
  .node-type-product .field-name-field-related-products > .field-items {
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: repeat(1, minmax(0, 1fr)); }
    .node-type-product .field-name-field-related-products > .field-items .views-row {
      margin-bottom: 3rem; }
    .node-type-product .field-name-field-related-products > .field-items .field-item {
      margin-bottom: 3rem; } }

/*------------------------------------*\
    Page: News List
\*------------------------------------*/
.page-news .view-news .view-content {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .page-news .view-news .view-content .views-row .node-news .field-name-title {
    margin: 1rem 0; }
    .page-news .view-news .view-content .views-row .node-news .field-name-title h2 {
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.5; }
      .page-news .view-news .view-content .views-row .node-news .field-name-title h2 a:hover {
        color: #4d87ca; }
  .page-news .view-news .view-content .views-row .node-news .field-name-field-summary {
    color: #555;
    font-size: 1.6rem;
    font-weight: 300;
    height: 7.5rem;
    overflow-y: hidden; }
  .page-news .view-news .view-content .views-row .node-news .field-name-node-link a {
    color: #4d87ca;
    transition: color 0.3s; }
    .page-news .view-news .view-content .views-row .node-news .field-name-node-link a:hover {
      color: #9cbce1; }
    .page-news .view-news .view-content .views-row .node-news .field-name-node-link a:after {
      content: ">";
      margin-top: 2px;
      margin-left: 1rem; }
  .page-news .view-news .view-content .views-row .node-news img {
    width: 100%;
    height: 25rem;
    object-fit: cover; }
  @media screen and (max-width: 1025px) {
    .page-news .view-news .view-content {
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  @media screen and (max-width: 769px) {
    .page-news .view-news .view-content {
      display: grid;
      grid-gap: 0;
      grid-template-columns: repeat(1, minmax(0, 1fr)); }
      .page-news .view-news .view-content .views-row {
        margin-bottom: 3rem; }
      .page-news .view-news .view-content .views-row {
        margin-bottom: 3rem; } }

/*------------------------------------*\
    Paeg: News
\*------------------------------------*/
.node-type-news article footer, .node-type-news article header {
  display: none; }

.node-type-news .news-wrapper {
  display: flex;
  justify-content: space-between; }

.node-type-news .news-left {
  width: 40%; }
  .node-type-news .news-left img {
    width: 100%; }

.node-type-news .news-right {
  width: 58%; }
  .node-type-news .news-right .field-name-title {
    font-size: 3rem;
    font-weight: 300; }
  .node-type-news .news-right .field-name-field-summary {
    font-weight: bold;
    margin: 2rem 0; }

@media screen and (max-width: 1025px) {
  .node-type-news .news-wrapper {
    flex-wrap: wrap; }
  .node-type-news .news-left {
    width: 100%;
    margin-bottom: 3rem; }
  .node-type-news .news-right {
    width: 100%; } }

/*------------------------------------*\
    Page: contact
\*------------------------------------*/
.page-node.contact .content-wrapper {
  display: flex; }

.page-node.contact .region-content {
  width: 40%;
  padding-left: 5rem; }
  .page-node.contact .region-content .contact-detail {
    margin-bottom: 3rem;
    font-size: 1.6rem; }
    .page-node.contact .region-content .contact-detail .contact-label {
      display: inline-block;
      width: 9rem; }
    .page-node.contact .region-content .contact-detail .contact-text {
      display: inline-block;
      width: 32rem;
      vertical-align: top; }
  .page-node.contact .region-content .contact-map iframe {
    width: 100%;
    height: 400px; }

.page-node.contact .region-content-left {
  width: 60%; }
  .page-node.contact .region-content-left p {
    margin-bottom: 2rem; }
  .page-node.contact .region-content-left .form-item {
    margin-bottom: 1.5rem; }
  .page-node.contact .region-content-left form input,
  .page-node.contact .region-content-left form textarea {
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 1rem;
    width: 100%; }
  .page-node.contact .region-content-left form .form-actions button {
    background-color: #4d87ca;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    font-size: 1.4rem;
    text-align: center; }
  .page-node.contact .region-content-left form .form-actions button:hover, .page-node.contact .region-content-left form .form-actions button:active {
    background-color: #74a1d6; }

@media screen and (max-width: 769px) {
  .page-node.contact .content-wrapper {
    flex-wrap: wrap; }
  .page-node.contact .region-content {
    width: 100%;
    padding-right: 0;
    margin-bottom: 4rem; }
  .page-node.contact .region-content-left {
    width: 100%; } }

/*------------------------------------*\
   Page: Cart
\*------------------------------------*/
.page-cart .content {
  min-height: auto; }

.page-cart #cart-form-pane form .clearfix:after {
  display: none; }

.page-cart #cart-form-pane table {
  width: 100%; }
  .page-cart #cart-form-pane table thead {
    background-color: #4d87ca;
    color: #fff; }
    .page-cart #cart-form-pane table thead th {
      height: 6rem;
      text-align: center; }
  .page-cart #cart-form-pane table tbody tr:not(:last-child) {
    border-bottom: 1px solid #eee; }
    .page-cart #cart-form-pane table tbody tr:not(:last-child) td {
      text-align: center;
      height: 120px;
      width: auto !important; }
      .page-cart #cart-form-pane table tbody tr:not(:last-child) td.qty .form-control {
        width: 50px;
        margin: 0 auto; }
    .page-cart #cart-form-pane table tbody tr:not(:last-child) button[type="submit"] {
      width: 100px; }
      .page-cart #cart-form-pane table tbody tr:not(:last-child) button[type="submit"] {
        background-color: #e48953;
        border-radius: 5px;
        display: inline-block;
        color: #fff;
        text-transform: uppercase;
        padding: 0.8rem 2rem;
        transition: all 0.5s;
        font-size: 1.4rem;
        text-align: center; }
      .page-cart #cart-form-pane table tbody tr:not(:last-child) button[type="submit"]:hover, .page-cart #cart-form-pane table tbody tr:not(:last-child) button[type="submit"]:active {
        background-color: #eba77f; }
    .page-cart #cart-form-pane table tbody tr:not(:last-child) input {
      border: 1px solid #eee;
      text-align: center;
      border-radius: 3px;
      height: 30px;
      color: #333; }
  .page-cart #cart-form-pane table tbody tr:last-child {
    height: 100px; }

.page-cart #cart-form-pane .form-actions {
  float: right;
  padding-bottom: 1rem; }
  .page-cart #cart-form-pane .form-actions a,
  .page-cart #cart-form-pane .form-actions button {
    margin-left: 1rem; }
  .page-cart #cart-form-pane .form-actions a {
    margin-top: 0; }
    .page-cart #cart-form-pane .form-actions a {
      background-color: #4d87ca;
      border-radius: 5px;
      display: inline-block;
      color: #fff;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      transition: all 0.5s;
      font-size: 1.4rem;
      text-align: center; }
    .page-cart #cart-form-pane .form-actions a:hover, .page-cart #cart-form-pane .form-actions a:active {
      background-color: #74a1d6; }
  .page-cart #cart-form-pane .form-actions button[name="update-cart"] {
    background-color: #e48953;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    font-size: 1.4rem;
    text-align: center; }
  .page-cart #cart-form-pane .form-actions button[name="update-cart"]:hover, .page-cart #cart-form-pane .form-actions button[name="update-cart"]:active {
    background-color: #eba77f; }
  .page-cart #cart-form-pane .form-actions button[name="op"] {
    background-color: #86bc86;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    font-size: 1.4rem;
    text-align: center; }
  .page-cart #cart-form-pane .form-actions button[name="op"]:hover, .page-cart #cart-form-pane .form-actions button[name="op"]:active {
    background-color: #a7cea7; }
  @media screen and (max-width: 769px) {
    .page-cart #cart-form-pane .form-actions {
      margin-top: 1rem;
      width: 100%;
      flex-direction: column; }
      .page-cart #cart-form-pane .form-actions a,
      .page-cart #cart-form-pane .form-actions button {
        margin-left: 0;
        margin-bottom: 1rem;
        width: 100%; } }

/*
======================================================
 Cart
======================================================
*/
#uc-cart-view-form table td {
  vertical-align: middle !important; }
  #uc-cart-view-form table td.image {
    width: 400px; }
    #uc-cart-view-form table td.image img {
      padding: 0; }
  #uc-cart-view-form table td.remove .btn {
    margin-right: 15px;
    margin-left: 15px; }

/*
======================================================
 Coupon Blocks
======================================================
*/
#uc-cart-pane-coupon {
  @apply tw-flex;
  @apply tw-w-full;
  margin-top: 1rem;
  border: none;
  border-top: solid 1px #e2e8f0; }
  #uc-cart-pane-coupon form#uc-coupon-form {
    @apply tw-flex;
    @apply tw-w-full;
    @apply tw-justify-center; }
    #uc-cart-pane-coupon form#uc-coupon-form > div {
      padding: 2rem;
      margin: 2rem 0;
      border: solid 1px #e2e8f0; }
    #uc-cart-pane-coupon form#uc-coupon-form h3 {
      margin: 0 0 1rem 0;
      font-weight: bold; }
    #uc-cart-pane-coupon form#uc-coupon-form .form-control.form-text {
      border: solid 1px #e2e8f0; }
    #uc-cart-pane-coupon form#uc-coupon-form .help-block {
      padding: 1rem 0;
      font-size: 1.2rem; }
    #uc-cart-pane-coupon form#uc-coupon-form .btn {
      background-color: #4d87ca;
      border-radius: 5px;
      display: inline-block;
      color: #fff;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      transition: all 0.5s;
      font-size: 1.4rem;
      text-align: center; }
    #uc-cart-pane-coupon form#uc-coupon-form .btn:hover, #uc-cart-pane-coupon form#uc-coupon-form .btn:active {
      background-color: #74a1d6; }
  @media screen and (min-width: 769px) {
    #uc-cart-pane-coupon form#uc-coupon-form > div {
      margin: 2rem; } }

/*------------------------------------*\
    Paeg: Checkout
\*------------------------------------*/
.page-cart-checkout .region-content {
  /*
    ======================================================
     Block: Checkout Order
    ======================================================
    */ }
  .page-cart-checkout .region-content table.cart-review {
    width: 100%; }
    .page-cart-checkout .region-content table.cart-review td.products,
    .page-cart-checkout .region-content table.cart-review th.products {
      width: 60%; }
    .page-cart-checkout .region-content table.cart-review thead {
      background-color: #4d87ca;
      color: #fff; }
      .page-cart-checkout .region-content table.cart-review thead th {
        height: 4rem;
        text-align: center; }
    .page-cart-checkout .region-content table.cart-review tbody tr:not(:last-child) {
      border-bottom: 1px solid #eee; }
      .page-cart-checkout .region-content table.cart-review tbody tr:not(:last-child) td {
        text-align: center;
        height: 60px; }
    .page-cart-checkout .region-content table.cart-review tbody tr:last-child {
      height: 80px; }
  .page-cart-checkout .region-content .uc-cart-checkout-form > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div .fieldset,
    .page-cart-checkout .region-content .uc-cart-checkout-form > div .form-actions {
      display: flex; }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div .address-pane-table > table {
      width: 100%; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div .address-pane-table > table .field-label {
        font-weight: normal;
        width: 30%;
        padding: 0;
        text-align: left; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div .address-pane-table > table td {
        padding-top: 1rem;
        padding-bottom: 1rem; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div .address-pane-table > table input,
      .page-cart-checkout .region-content .uc-cart-checkout-form > div .address-pane-table > table select,
      .page-cart-checkout .region-content .uc-cart-checkout-form > div .address-pane-table > table .chosen-single {
        margin: 0;
        background-color: #fff !important; }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div .panel-default {
      width: 100%; }
      @media screen and (min-width: 1025px) {
        .page-cart-checkout .region-content .uc-cart-checkout-form > div .panel-default#delivery-pane, .page-cart-checkout .region-content .uc-cart-checkout-form > div .panel-default#billing-pane {
          width: calc(50% - 1rem); } }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div .form-item {
      margin-bottom: 1rem; }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div #billing-pane {
      margin-left: auto;
      font-size: 1.5rem;
      font-weight: normal;
      color: #555; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #billing-pane .control-label {
        display: flex;
        font-weight: 500;
        color: #555;
        align-items: center; }
        .page-cart-checkout .region-content .uc-cart-checkout-form > div #billing-pane .control-label input[type="checkbox"] {
          flex: 0 0 10px;
          margin-right: 1rem; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #billing-pane .form-item-panes-billing-copy-address .control-label input[type="checkbox"] {
        position: relative;
        margin-top: 0; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #billing-pane .form-item-panes-billing-select-address .control-label {
        color: #000; }
    @media screen and (max-width: 769px) {
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane #line-items-div {
        width: 100%; } }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method {
      padding: 0;
      margin-bottom: 0; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method .control-label,
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method .form-radios {
        width: 100%; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method > .control-label {
        position: relative;
        width: auto;
        flex: 0 0 auto;
        font-size: 12px;
        font-weight: normal; }
        .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method > .control-label input {
          width: auto; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method #pxpay-includes {
        display: flex;
        flex-wrap: wrap;
        font-size: 0;
        align-items: center; }
        .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method #pxpay-includes input {
          visibility: hidden; }
        .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method #pxpay-includes > a {
          display: flex;
          flex: 0 0 200px; }
        .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method #pxpay-includes img {
          width: auto;
          height: auto; }
        .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-pane .form-item-panes-payment-payment-method #pxpay-includes br {
          display: none; }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div #quotes-pane #quote {
      border: none;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #quotes-pane #quote .control-label {
        display: flex;
        align-items: center; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #quotes-pane #quote input[type="radio"] {
        flex: 0 0 10px;
        margin-right: 1rem; }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div .form-item-panes-payment-payment-method {
      display: flex;
      align-items: center; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div .form-item-panes-payment-payment-method input[type="radio"] {
        flex: 0 0 10px;
        margin-right: 1rem; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div .form-item-panes-payment-payment-method label {
        align-items: center; }
      @media screen and (max-width: 769px) {
        .page-cart-checkout .region-content .uc-cart-checkout-form > div .form-item-panes-payment-payment-method {
          width: 100%; } }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-details {
      width: 50%; }
      @media screen and (max-width: 769px) {
        .page-cart-checkout .region-content .uc-cart-checkout-form > div #payment-details {
          width: 100%; } }
    @media screen and (max-width: 769px) {
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #delivery-pane,
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #billing-pane {
        width: 100%;
        margin-left: 0; } }
    .page-cart-checkout .region-content .uc-cart-checkout-form > div #edit-actions {
      justify-content: flex-end; }
      .page-cart-checkout .region-content .uc-cart-checkout-form > div #edit-actions button {
        margin-left: 1rem; }
  @media screen and (min-width: 641px) {
    .page-cart-checkout .region-content .order-review-table {
      width: 80%; } }
  .page-cart-checkout .region-content #uc-cart-checkout-review-form .form-actions {
    position: relative;
    padding: 0 1rem 1rem 1rem;
    display: block; }
    .page-cart-checkout .region-content #uc-cart-checkout-review-form .form-actions .btn {
      background-color: #4d87ca;
      border-radius: 5px;
      display: inline-block;
      color: #fff;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      transition: all 0.5s;
      font-size: 1.4rem;
      text-align: center; }
    .page-cart-checkout .region-content #uc-cart-checkout-review-form .form-actions .btn:hover, .page-cart-checkout .region-content #uc-cart-checkout-review-form .form-actions .btn:active {
      background-color: #74a1d6; }
  .page-cart-checkout .region-content fieldset.panel {
    border: 1px solid #ddd;
    margin-bottom: 5rem; }
    .page-cart-checkout .region-content fieldset.panel .panel-heading {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      width: 100%;
      background: #4d87ca;
      color: #fff;
      padding: 1rem 0.5rem 1rem 2rem; }
    .page-cart-checkout .region-content fieldset.panel .panel-body {
      padding: 2rem; }
      .page-cart-checkout .region-content fieldset.panel .panel-body input,
      .page-cart-checkout .region-content fieldset.panel .panel-body select,
      .page-cart-checkout .region-content fieldset.panel .panel-body textarea,
      .page-cart-checkout .region-content fieldset.panel .panel-body select,
      .page-cart-checkout .region-content fieldset.panel .panel-body .chosen-single {
        border: 2px solid #c3d6ed !important;
        width: 100%;
        padding: 1rem;
        color: #333; }
      .page-cart-checkout .region-content fieldset.panel .panel-body label.control-label {
        font-weight: bold;
        display: block; }
      .page-cart-checkout .region-content fieldset.panel .panel-body .help-block {
        color: #999;
        margin: 0 0 1em 0;
        font-size: 1.3rem; }
      .page-cart-checkout .region-content fieldset.panel .panel-body .form-type-select {
        width: 100%; }
      .page-cart-checkout .region-content fieldset.panel .panel-body .chosen-container {
        width: 100% !important;
        font-size: 100%; }
      .page-cart-checkout .region-content fieldset.panel .panel-body .chosen-single {
        height: auto;
        padding: 1rem;
        border-radius: 0;
        border: 1px solid #eee;
        box-shadow: none;
        background: transparent; }
        .page-cart-checkout .region-content fieldset.panel .panel-body .chosen-single > div {
          display: flex;
          height: 100%;
          align-items: center; }
          .page-cart-checkout .region-content fieldset.panel .panel-body .chosen-single > div::before {
            content: "\f107";
            font-family: "FontAwesome"; }
          .page-cart-checkout .region-content fieldset.panel .panel-body .chosen-single > div b {
            display: none; }
      .page-cart-checkout .region-content fieldset.panel .panel-body button[type="submit"] {
        background-color: #4d87ca;
        border-radius: 5px;
        display: inline-block;
        color: #fff;
        text-transform: uppercase;
        padding: 0.8rem 2rem;
        transition: all 0.5s;
        font-size: 1.4rem;
        text-align: center; }
      .page-cart-checkout .region-content fieldset.panel .panel-body button[type="submit"]:hover, .page-cart-checkout .region-content fieldset.panel .panel-body button[type="submit"]:active {
        background-color: #74a1d6; }
  .page-cart-checkout .region-content #delivery-address-pane table {
    margin-top: 2rem; }
    .page-cart-checkout .region-content #delivery-address-pane table tr {
      height: 6rem; }
      .page-cart-checkout .region-content #delivery-address-pane table tr td.field-label {
        color: #555;
        font-weight: 500;
        font-size: 1.5rem;
        width: 15rem;
        text-align: left; }
    .page-cart-checkout .region-content #delivery-address-pane table input {
      border: none;
      background-color: #f1f1f1;
      color: #555;
      border-radius: 3px; }
  .page-cart-checkout .region-content #edit-cancel {
    background-color: #e48953;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    font-size: 1.4rem;
    text-align: center; }
  .page-cart-checkout .region-content #edit-cancel:hover, .page-cart-checkout .region-content #edit-cancel:active {
    background-color: #eba77f; }
  .page-cart-checkout .region-content #edit-continue {
    background-color: #86bc86;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    font-size: 1.4rem;
    text-align: center; }
  .page-cart-checkout .region-content #edit-continue:hover, .page-cart-checkout .region-content #edit-continue:active {
    background-color: #a7cea7; }
  .page-cart-checkout .region-content table.cart-review tbody tr td {
    height: auto; }
    .page-cart-checkout .region-content table.cart-review tbody tr td.products {
      text-align: left; }
    .page-cart-checkout .region-content table.cart-review tbody tr td.price {
      text-align: center; }
  .page-cart-checkout .region-content .order-review-table {
    width: 100%;
    border: none;
    margin-top: 30px; }
    .page-cart-checkout .region-content .order-review-table .pane-title-row {
      background-color: #9cbce1;
      border: none;
      color: #fff; }
    .page-cart-checkout .region-content .order-review-table tbody tr td {
      padding-top: 15px;
      padding-bottom: 15px;
      height: auto;
      vertical-align: middle; }

.page-cart-checkout
.region-content
#uc-cart-checkout-review-form
.form-actions {
  padding: 0 1rem; }
  .page-cart-checkout
.region-content
#uc-cart-checkout-review-form
.form-actions .btn {
    font-weight: bold; }

.about .grid-brands {
  display: grid;
  grid-gap: 1rem; }
  @media screen and (min-width: 641px) {
    .about .grid-brands {
      grid-template-columns: repeat(5, 1fr); } }
  .about .grid-brands img {
    width: 100%; }

.page-user {
  /*
  ======================================================
   User Tab
  ======================================================
  */
  /*
  ======================================================
   User Order
  ======================================================
  */ }
  .page-user .profile {
    position: relative;
    width: 100%;
    padding: 2rem;
    border: 1px dashed #eee; }
    .page-user .profile h3 {
      color: #4d87ca;
      padding-bottom: 1rem; }
      .page-user .profile h3:first-child {
        margin-top: 0; }
  .page-user .tabs--primary {
    display: flex;
    flex-wrap: wrap; }
    .page-user .tabs--primary > li {
      display: flex;
      flex: auto;
      padding-left: 10px;
      padding-right: 10px; }
      .page-user .tabs--primary > li.active {
        font-weight: bold; }
  .page-user .view-uc-orders-user table thead tr th {
    background-color: #4d87ca;
    color: #FFF; }
  .page-user .view-uc-orders-user table tbody tr:not(.views-row-first) > td {
    border-top: 1px dashed #e2e8f0; }

/*------------------------------------*\
  Our range
\*------------------------------------*/
.our-range {
  /** view **/ }
  .our-range .view-content {
    @apply tw-w-full
        tw-grid;
    gap: 2rem; }
    .our-range .view-content h2 {
      font-size: 3.5rem; }
      .our-range .view-content h2 a {
        color: #000; }
    .our-range .view-content a {
      color: #4d87ca; }
    .our-range .view-content .group-left {
      @apply tw-w-full tw-mb-4; }
    .our-range .view-content > .views-row {
      @apply tw-flex
            tw-w-full; }
      .our-range .view-content > .views-row .vocabulary-category {
        @apply tw-flex-col; }
  @media screen and (min-width: 769px) {
    .our-range .view-content {
      @apply tw-grid-cols-2;
      gap: 4rem; } }
  @media screen and (min-width: 1025px) {
    .our-range .view-content > .views-row .vocabulary-category {
      @apply tw-flex-row; }
    .our-range .view-content > .views-row .group-left {
      width: 250px; }
    .our-range .view-content > .views-row .group-right {
      flex: 1; }
    .our-range .view-content > .views-row .group-right {
      @apply tw-pl-16; } }

/*------------------------------------*\
  Product Care
\*------------------------------------*/
.product-care--block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  /** Responsive **/ }
  .product-care--block > div {
    padding: 0 !important; }
  .product-care--block a {
    padding: 0 !important;
    width: 100%;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .product-care--block a span {
      flex: 1;
      padding: 2rem 2rem;
      font-size: 2.5rem;
      text-align: center; }
    .product-care--block a:hover {
      color: #4d87ca; }
  .product-care--block img {
    width: 388px;
    height: 203px; }
  .product-care--block span {
    text-align: center; }
  @media screen and (min-width: 1025px) {
    .product-care--block {
      flex-direction: row;
      gap: 5rem;
      margin-top: 2rem; } }
