/*------------------------------------*\
    Paeg: News
\*------------------------------------*/
.node-type-news {
  article {
    footer, header {display: none;}
  }

  .news-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .news-left {
    width: 40%;

    img {
      width: 100%;
    }
  }

  .news-right {
    width: 58%;

    .field-name-title {
      font-size: 3rem;
      font-weight: 300;
    }

    .field-name-field-summary {
      font-weight: bold;
      margin: 2rem 0;
    }
  }

  @include less-than($screen-lg) {
    .news-wrapper {
      flex-wrap: wrap;
    }

    .news-left {
      width: 100%;
      margin-bottom: 3rem;
    }
    .news-right {
      width: 100%;
    }
  }
}
