/*------------------------------------*\
    Typography
\*------------------------------------*/

html {
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

body {
  font-size: 1.6rem;
  color: #000;
}

p {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 300;
  margin-bottom: 10px;
}

h3 {
  margin: 1em 0 0.5em;
  font-size: 16pt;
}

.region-content {
  ol, ul {
    li {
      list-style: outside;
      font-weight: 300;
    }
  }
  ul{
    padding-left: 1em;
  }
  ol{
    > li{
      list-style-position: inside;
      display:list-item;
      list-style-type: decimal;
    }  
  }

}

.hide-info {
  display: none;
}
