/*------------------------------------*\
    Page: Home
\*------------------------------------*/
.front {
  overflow-x: hidden; // hide overflowing element pushed by .block-brands;
  .breadcrumb {
    display: none;
  }

  /*
  ======================================================
  : Page Content
  ======================================================
  */
  .block-system {
    margin: 8rem 0;

    .content-left {
      padding-right: 5rem;

      p {
        margin-bottom: 3rem;
        strong {
          font-weight: bold;
        }
      }
    }

    @include less-than($screen-lg) {
      margin: 3rem 0;

      .tw-flex {
        @apply tw-flex-wrap;
      }

      .content-left {
        padding-right: 0;
        width: 100% !important;
      }
      .content-right {
        order: -1;
        margin-bottom: 2rem;
        width: 100% !important;
      }
    }
  }

  /** Region Content **/
  .region-content {
    > section.block {
      position: relative;
    }

    section:not(.block-system) {
      .view-header {
        a {
          position: absolute;
          right: 0;
          top: 0;
          @include link;
        }
      }
    }

    .block-title {
      font-weight: 500;
      font-size: 1.8rem;
      display: inline;
      padding: 1rem 0;
      text-transform: uppercase;
      border-bottom: 6px solid #4d87ca;
      @include less-than($screen-sm) {
        font-size: 1.5rem;
      }
    }
  }
}

/*
======================================================
: Brands
======================================================
*/
.block-brands {
  margin: 4rem 0;
  position: relative;

  &:after {
    content: "";
    display: block;
    height: 80%;
    position: absolute;
    width: 100vw;
    left: calc(0px - (100vw - 100%) / 2);
    top: 10%;
    background: #f3f3f3;
    z-index: -1;
    visibility: visible;
  }

  .view-content {
    @include grid-layout(5, 3rem);

    img {
      width: 100%;
    }
  }

  @include less-than($screen-lg) {
    .view-content {
      @include grid-layout(1, 0rem);
      .views-row {
        margin-bottom: 3rem;
      }
    }

    &:after {
      top: 4%;
    }
  }
}

/*
======================================================
: Popular Prodcuts - quick tabs
======================================================
*/
.block-quicktabs {
  margin: 5rem 0 3rem;

  .contextual-links-region {
    position: static;
  }

  ul.quicktabs-tabs {
    @include tabs;
  }

  .view-footer {
    display: none;
    text-align: center;
    margin: 5rem 0 2rem;

    a {
      @include link;
    }
  }

  .quicktabs_main {
    .view-content {
      border-top: 1px solid #e9e9e9;
      margin-top: 15px;
      padding-top: 3rem;
      @include grid-layout(5, 3rem);
      @include less-than($screen-lg) {
        @include grid-layout(2, 3rem);
      }

      @include product;
      @include less-than($screen-sm) {
        margin-top: 0;

        @include grid-layout(1, 0rem);
        .views-row {
          margin-bottom: 3rem;
        }
      }
    }
  }

  @include less-than($screen-sm) {
    .view-header {
      display: none;
    }
    .view-footer {
      display: block;
    }
  }
}

/*
======================================================
: News
======================================================
*/
.block-news {
  margin: 5rem 0 10rem;

  .view-content {
    padding-top: 3rem;
    border-top: 1px solid #e9e9e9;
    margin-top: 15px;
    @include grid-layout(3, 3rem);
    @include news;
  }

  @include less-than($screen-md) {
    margin: 5rem 0 0;

    .view-content {
      @include grid-layout(1, 0rem);
      .views-row {
        margin-bottom: 3rem;
      }
    }
  }

  .views-row {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
    padding: 2rem;

    .field-name-node-link {
      margin-top: 2rem;
      a {
        width: 100%;
        text-align: center;
        color: $color-white !important;
        @include btn($color-primary);
      }
    }
  }
}
