/*------------------------------------*\
   Page: Cart
\*------------------------------------*/
.page-cart {
  .content {
    min-height: auto;
  }
  #cart-form-pane {
    form {
      .clearfix:after {
        display: none;
      }
    }
    table {
      width: 100%;

      thead {
        background-color: #4d87ca;
        color: #fff;
        th {
          height: 6rem;
          text-align: center;
        }
      }

      tbody {
        tr:not(:last-child) {
          border-bottom: 1px solid #eee;

          td {
            text-align: center;
            height: 120px;
            width: auto !important;

            &.qty{
              .form-control{
                width: 50px;
                margin: 0 auto;
              }
            }

          }

          button[type="submit"] {
            width: 100px;
            @include btn($color-danger);
          }

          input {
            border: 1px solid #eee;
            text-align: center;
            border-radius: 3px;
            height: 30px;
            color: #333;
          }
        }
        tr:last-child {
          height: 100px;
        }
      }
    }

    .form-actions {
      float: right;
      padding-bottom: 1rem;
      a,
      button {
        margin-left: 1rem;
      }

      a {
        margin-top: 0;
        @include btn($color-primary);
      }

      button[name="update-cart"] {
        @include btn(#e48953);
      }

      button[name="op"] {
        @include btn($color-success);
      }

      @include less-than($screen-md){
        margin-top: 1rem;
        width: 100%;
        flex-direction: column;
        a,
        button {
          margin-left: 0;
          margin-bottom: 1rem;
          width: 100%;
        }
      }

    }
  }
}

/*
======================================================
 Cart
======================================================
*/

#uc-cart-view-form {
  table {
    td {
      vertical-align: middle !important;

      &.image {
        width: 400px;

        img {
          padding: 0;
        }
      }

      &.remove {
        .btn {
          margin-right: 15px;
          margin-left: 15px;
        }
      }
    }
  }
}

/*
======================================================
 Coupon Blocks
======================================================
*/

#uc-cart-pane-coupon{
  @apply tw-flex;
  @apply tw-w-full;
  margin-top: 1rem;
  border: none;
  border-top: solid 1px #e2e8f0;

  form#uc-coupon-form{
    @apply tw-flex;
    @apply tw-w-full;
    @apply tw-justify-center;
    > div{
      padding: 2rem;
      margin: 2rem 0;
      border: solid 1px #e2e8f0;
    }
    h3{
      margin: 0 0 1rem 0;
      font-weight: bold;
    }
    .form-control{
      &.form-text{
        border: solid 1px #e2e8f0;
      }
    }
    .help-block{
      padding: 1rem 0;
      font-size: 1.2rem
    }
    .btn{
      @include btn($color-primary);
    }
  }

  @include larger-than($screen-md){
    form#uc-coupon-form{
      > div{
        margin: 2rem;
      }
    }
  }

}
